import { FormattedMessage } from 'react-intl';

import { getReportDataForCurrentDateRange } from '@/actions/reports';
import PublishButton from '@/components/common/PublishButton.jsx';
import TopBar from '@/components/TopBars/TopBar.jsx';
import { newReportsModesIds } from '@/constants/viewDateModes.js';

import TopBarCalendar from './Calendar.redux.js';
import TopBarEmploymentConditionsFilter from './EmploymentConditionsFilter.redux.js';
import TopBarJobTitleFilterContainer from './JobTitleFilter.redux.js';
import TopBarMultipleLocationFilter from './LocationFilter.redux.js';
import { ReportsFilters } from '@/types/reports.types.js';

export type ReportsTopBarProps = {
  isButtonDisabled: boolean;
  getReportDataForCurrentDateRange: typeof getReportDataForCurrentDateRange;
  filters: ReportsFilters;
  modifiers?: string[];
  className?: string;
};
const ReportsTopBar = ({
  isButtonDisabled,
  className,
  getReportDataForCurrentDateRange,
  modifiers,
  filters,
}: ReportsTopBarProps) => {
  const hasSelectedLocations = filters.selectedLocations.length > 0;
  const hasSelectedJobTitles = filters.selectedJobTitles.length > 0;
  const hasSelectedEmploymentConditions = filters.selectedEmploymentConditions.length > 0;
  const isPublishButtonDisabled =
    isButtonDisabled || !hasSelectedLocations || !hasSelectedJobTitles || !hasSelectedEmploymentConditions;

  return (
    <TopBar
      className={className}
      modifiers={modifiers}
      leftElements={[
        <TopBarCalendar key="calswitch" modes={newReportsModesIds} />,
        <TopBarMultipleLocationFilter key="locationFilter" selectAllWhenEmpty />,
        <TopBarJobTitleFilterContainer key="jobTitleFilter" storeOnlyIds selectAllWhenEmpty />,
        <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" selectAllWhenEmpty />,
      ]}
      rightElements={[
        <PublishButton
          text={<FormattedMessage id="loadData" defaultMessage="Wczytaj" />}
          onClickFunc={getReportDataForCurrentDateRange}
          key="getData"
          disabled={isPublishButtonDisabled}
          icon="update"
        />,
      ]}
    />
  );
};

export default ReportsTopBar;
