import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import KadroCalendarPopoverInput from '@/components/common/inputs/KadroCalendarPopoverInput.jsx';
import { KadroMultiSelect } from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { employmentConditionsFilterOptions } from '@/constants/employmentConditions.js';
import { useMultiSelect } from '@/hooks';
import { convertDateToStandardFormat, getCalendarRangeText, getDateModeFromDates } from '@/utils/dateHelper.js';
import {
  getEmploymentConditionsMessages,
  getJobTitlesMessages,
  getLocationsMessages,
} from '@/utils/filters/filters.helpers';

import { filtersModalMessages } from './FiltersModal.messages';

const MODAL_MODIFIERS = ['narrow'];

const FiltersModal = (
  {
    isOpen,
    handleSubmit,
    locations,
    jobTitles,
    employmentConditions,
    changeLocationsFilter,
    changeJobTitlesFilter,
    changeEmploymentConditionsFilter,
    hideModal,
    filters,
    mainDateStore,
    isConfirmButtonDisabled,
    mainDateChangeModeAndDate,
  },
  { intl },
) => {
  const { selectedLocations, selectedJobTitles, selectedEmploymentConditions } = filters;
  const allEmploymentConditions = [...employmentConditionsFilterOptions, ...employmentConditions];
  const calendarRangeText = useMemo(
    () => getCalendarRangeText(mainDateStore.customDate.start, mainDateStore.customDate.end),
    [mainDateStore.customDate],
  );
  const onDatesChange = useCallback(
    event => {
      const dateMode = getDateModeFromDates({
        start: convertDateToStandardFormat(event.start),
        end: convertDateToStandardFormat(event.end),
      });
      mainDateChangeModeAndDate(
        convertDateToStandardFormat(event.start),
        convertDateToStandardFormat(event.end),
        dateMode.type,
      );
    },
    [mainDateChangeModeAndDate],
  );
  const jobTitleOptions = useMemo(
    () =>
      jobTitles.map(jobTitle => ({
        value: jobTitle.id,
        label: jobTitle.title,
        active: selectedJobTitles.length ? selectedJobTitles.includes(jobTitle.id) : true,
      })),
    [jobTitles, selectedJobTitles],
  );

  const locationOptions = useMemo(
    () =>
      locations.map(location => ({
        value: location.id,
        label: location.name,
        active: selectedLocations.includes(location.id),
      })),
    [locations, selectedLocations],
  );
  const employmentConditionsOptions = useMemo(
    () =>
      allEmploymentConditions.map(employmentCondition => ({
        value: employmentCondition.id,
        label: employmentCondition.name,
        active: selectedEmploymentConditions.includes(employmentCondition.id),
      })),
    [employmentConditions, selectedEmploymentConditions],
  );

  const onSubmit = () => {
    if (handleSubmit) {
      handleSubmit();
    }
    hideModal();
  };
  const [jobTitlesItems, jobTitlesHandlers] = useMultiSelect(jobTitleOptions, changeJobTitlesFilter);
  const [locationsItems, locationsHandlers] = useMultiSelect(locationOptions, changeLocationsFilter);
  const [employmentConditionsItems, employmentConditionsHandlers] = useMultiSelect(
    employmentConditionsOptions,
    changeEmploymentConditionsFilter,
  );
  const jobTitlesMessages = useMemo(() => getJobTitlesMessages(intl, jobTitlesItems), [jobTitlesItems]);
  const locationMessages = useMemo(() => getLocationsMessages(intl, locationsItems), [locationsItems]);
  const employmentCondictionsMessages = useMemo(
    () => getEmploymentConditionsMessages(intl, employmentConditionsItems),
    [employmentConditionsItems],
  );

  return (
    <MDKadroModal
      show={isOpen}
      modifiers={MODAL_MODIFIERS}
      title={intl.formatMessage(filtersModalMessages.modalHeader)}
      onSubmit={onSubmit}
      hideCancelButton
      confirmText={intl.formatMessage(filtersModalMessages.show)}
      onHide={hideModal}
      disableConfirm={isConfirmButtonDisabled}
      withoutOverlayScroll
    >
      <KadroCalendarPopoverInput
        inputValue={calendarRangeText}
        selectedRange={mainDateStore.customDate}
        onRangeChange={onDatesChange}
        modifiers="extra-padding"
        hidePicker={false}
        mainDateStore={mainDateStore}
      />
      <KadroMultiSelect
        items={jobTitlesItems}
        messages={jobTitlesMessages}
        handlers={jobTitlesHandlers}
        withoutFooter
      />
      <KadroMultiSelect items={locationsItems} messages={locationMessages} handlers={locationsHandlers} withoutFooter />
      <KadroMultiSelect
        items={employmentConditionsItems}
        messages={employmentCondictionsMessages}
        handlers={employmentConditionsHandlers}
        withoutFooter
      />
    </MDKadroModal>
  );
};

FiltersModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

FiltersModal.propTypes = {
  hideModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateChangeModeAndDate: PropTypes.func,
  changeLocationsFilter: PropTypes.func,
  changeJobTitlesFilter: PropTypes.func,
  changeEmploymentConditionsFilter: PropTypes.func,
  filters: PropTypes.shape({
    selectedLocations: PropTypes.arrayOf(PropTypes.shape({})),
    selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
    selectedEmploymentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  isConfirmButtonDisabled: PropTypes.bool,
};

export default FiltersModal;
