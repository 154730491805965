import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TableBodyProps } from 'react-table';

import { ContextWithIntl } from '@/types/intl.types';
import { TableData } from '@/types/reports.types';
import { ReportsColumn, ReportsRow } from '@/types/table.types';

import { getCellWidth } from '../MDTable.helpers';
import { calculateSummary } from './MDTableSummary.helpers';
import { messages } from './MDTableSummary.mesages';

type MDTableSummaryProps = {
  columns: ReportsColumn[];
  data: Record<string, TableData & { date: string }>[];
  getTableBodyProps: () => TableBodyProps;
  rows: ReportsRow[];
  withHorizontalScroll: boolean;
  withBigPadding: boolean;
  shadowRightColumn: number;
  shadowLeftColumn: number;
};

const MDTableSummary = (
  {
    columns,
    data,
    getTableBodyProps,
    rows,
    withHorizontalScroll,
    withBigPadding,
    shadowRightColumn,
    shadowLeftColumn,
  }: MDTableSummaryProps,
  { intl }: ContextWithIntl,
) => (
  <div className="mdTable__summaryRow" {...getTableBodyProps()}>
    <div className="mdTable__row">
      {columns.map((column, columnIndex) => {
        const cellProps = {
          style: {
            width: column.totalWidth,
          },
        };
        const width = getCellWidth(withHorizontalScroll, cellProps?.style?.width, rows[0]?.cells, columnIndex + 1, 1);
        const shouldHasShadowRight = column.id === shadowRightColumn;
        const shouldHasShadowLeft = column.id === shadowLeftColumn;

        const cellClassName = classNames('mdTable__cell', {
          'mdTable__cell--bigPadding': withBigPadding,
          'mdTable__cell--shadowRight': shouldHasShadowRight,
          'mdTable__cell--shadowLeft': shouldHasShadowLeft,
        });

        return columnIndex === 0 ? (
          <div key={columnIndex} {...cellProps} className={cellClassName} style={{ ...cellProps.style, ...width }}>
            <div className="mdTable__cell-content">{intl.formatMessage(messages.rowTitle)}</div>
          </div>
        ) : (
          <div key={columnIndex} {...cellProps} className={cellClassName} style={{ ...cellProps.style, ...width }}>
            <span className="mdTable__label--mobile">{column.render('Header')}</span>
            <div className="mdTable__cell-content">{calculateSummary(column, data)}</div>
          </div>
        );
      })}
    </div>
  </div>
);
MDTableSummary.contextTypes = {
  intl: PropTypes.shape({}),
};

export default MDTableSummary;
