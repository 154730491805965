import { TableData } from '@/types/reports.types';
import { ReportsColumn } from '@/types/table.types';
import { roundToTwoSigDigits } from '@/utils/baseHelpers';

export const calculateSummary = (column: ReportsColumn, data: Record<string, TableData & { date: string }>[]) => {
  const columnData = data.map(row => column.accessor(row));

  const summaryData = columnData.reduce((acc, val) => acc + val, 0);

  return column.Cell({ value: roundToTwoSigDigits(summaryData) });
};
