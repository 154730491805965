import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDDatesPicker, MDSelect, MDTimeInput } from '@/components/common/inputs/MDComponents';
import MDSelectPopover from '@/components/common/inputs/MDSelect/MDSelectPopover/MDSelectPopover';
import MultiSelectOptionManager from '@/components/common/inputs/MultiSelectOptionManager/MultiSelectOptionManager';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { useAppSelector } from '@/redux-store';
import { selectOpenShiftsAssignments } from '@/redux-store/openShiftsAssignments/openShiftsAssignments.selectors';
import { getDisabledDates, getFirstDateThatIsNotDisabledFromRange, moveDateForwardByOne } from '@/utils/dateHelper.js';
import { inputValidation } from '@/utils/inputValidation';
import { checkIfCanAddEditDeleteLabels, showLabelsFeature } from '@/utils/labels';

import {
  getLocationValue,
  getRelevantEmployeesAsOptions,
  getRelevantLocationsAsOptions,
  getRelevantLocationsAsOptionsForLoanedEmployee,
  getSingleEmployeeFilterLocationsAsOptions,
} from './CreateAttendanceModal.helpers';
import { messages } from './CreateAttendanceModal.messages';

import './createAttendanceModal.scss';

const Y_OFFSET_EMPLOYEE_SELECT = -15;

const CreateAttendanceModal = (
  {
    showModal,
    hideModal,
    minDate,
    maxDate,
    userEmployees,
    scheduleLocationFilter,
    userLocations,
    createAttendance,
    companyRoles,
    currentUser,
    showDatePicker,
    showEmployeePicker,
    singleEmployeeFilter,
    userPermissions,
    useMultipleLocationFilter,
    multipleLocationFilter,
    labels,
    loanEmployeesProposalsAssignments,
  },
  { intl },
) => {
  const [date, setDate] = useState(minDate);
  const [openShiftsAssignments, setOpenShiftsAssignments] = useState([]);
  const isWeekView = showDatePicker;
  const relevantLocations = useMemo(
    () =>
      showEmployeePicker
        ? getRelevantLocationsAsOptions(multipleLocationFilter, userLocations)
        : singleEmployeeFilter.locations
          ? getSingleEmployeeFilterLocationsAsOptions(singleEmployeeFilter)
          : getRelevantLocationsAsOptionsForLoanedEmployee(
              userLocations,
              loanEmployeesProposalsAssignments,
              singleEmployeeFilter.id,
              multipleLocationFilter,
              openShiftsAssignments,
            ),
    [
      showEmployeePicker,
      multipleLocationFilter,
      userLocations,
      singleEmployeeFilter,
      loanEmployeesProposalsAssignments,
      openShiftsAssignments,
    ],
  );
  
  const [selectedLocation, setSelectedLocation] = useState(
    relevantLocations.length ? relevantLocations[0].value : null,
  );

  const { role_id: roleId, role } = currentUser.user;
  const { permissions } = userPermissions;

  const disabledAddAndEditLabels = useMemo(
    () => !checkIfCanAddEditDeleteLabels(companyRoles, roleId, role, permissions),
    [companyRoles, roleId, role, permissions],
  );

  const showLabelsSelect = showLabelsFeature(role, permissions);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const handleLocationChange = value => setSelectedLocation(value);

  const relevantEmployees = useMemo(
    () => getRelevantEmployeesAsOptions(userEmployees, selectedLocation, companyRoles, currentUser, userPermissions),
    [companyRoles, currentUser, selectedLocation, userEmployees, userPermissions],
  );
  const defaultSelectedEmployee = !showEmployeePicker
    ? singleEmployeeFilter.id
    : relevantEmployees && relevantEmployees.length
      ? relevantEmployees[0].value
      : null;

  const [selectedEmployee, setSelectedEmployee] = useState(defaultSelectedEmployee);
  const openShiftsAssignmentsForEmployee = useAppSelector(selectOpenShiftsAssignments(selectedEmployee));
  useEffect(() => {
    setOpenShiftsAssignments(openShiftsAssignmentsForEmployee);
  }, [openShiftsAssignmentsForEmployee]);

  const handleEmployeeChange = value => setSelectedEmployee(value);

  const [hours, setHours] = useState('__:__-__:__');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onHide = () => {
    hideModal();
    const locationValue = getLocationValue(
      relevantLocations,
      useMultipleLocationFilter,
      multipleLocationFilter,
      scheduleLocationFilter,
    );
    setSelectedLocation(locationValue);
    setSelectedEmployee(showEmployeePicker ? relevantEmployees[0]?.value : singleEmployeeFilter.id);
    setHours('__:__-__:__');
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    const [startHour, finishHour] = hours.split('-');
    const error = inputValidation('attendanceHours', hours);
    if (error !== '') {
      setErrorMessage(error);
      return;
    }
    const startDate = date;
    const endDate = finishHour < startHour ? moveDateForwardByOne('day', startDate) : date;
    const startTimestamp = `${startDate} ${startHour}:00`;
    const endTimestamp = `${endDate} ${finishHour}:00`;
    const relevantEmployee = showEmployeePicker ? selectedEmployee : singleEmployeeFilter.id;

    try {
      setIsSubmitting(true);
      await createAttendance(relevantEmployee, startTimestamp, endTimestamp, selectedLocation, selectedLabels);
    } finally {
      setIsSubmitting(false);
      onHide();
    }
  };

  useEffect(() => {
    const locationValue = getLocationValue(
      relevantLocations,
      useMultipleLocationFilter,
      multipleLocationFilter,
      scheduleLocationFilter,
    );
    setSelectedLocation(locationValue);
  }, [scheduleLocationFilter, relevantLocations, useMultipleLocationFilter, multipleLocationFilter]);

  useEffect(() => {
    if (showEmployeePicker) {
      setSelectedEmployee(relevantEmployees.length ? relevantEmployees[0].value : null);
    } else {
      setSelectedEmployee(singleEmployeeFilter.id);
    }
  }, [relevantEmployees, scheduleLocationFilter, selectedLocation, showEmployeePicker, singleEmployeeFilter]);

  const disabledDates = useMemo(
    () =>
      getDisabledDates(
        userEmployees,
        selectedEmployee,
        selectedLocation,
        minDate,
        maxDate,
        loanEmployeesProposalsAssignments,
        openShiftsAssignments,
      ),
    [
      selectedLocation,
      selectedEmployee,
      loanEmployeesProposalsAssignments,
      userEmployees,
      minDate,
      maxDate,
      openShiftsAssignments,
    ],
  );

  const firstDateNotInDisabledDates = useMemo(
    () => getFirstDateThatIsNotDisabledFromRange(minDate, maxDate, disabledDates),
    [disabledDates, minDate, maxDate],
  );

  useEffect(() => {
    setDate(firstDateNotInDisabledDates || minDate);
  }, [minDate, firstDateNotInDisabledDates]);

  return (
    <MDKadroModal
      className="k-createAttendanceModal"
      show={showModal}
      title={intl.formatMessage(messages.title)}
      confirmText={intl.formatMessage(messages.confirmText)}
      onHide={onHide}
      modifiers={['narrow']}
      errorMessage={errorMessage && intl.formatMessage(errorMessage)}
      disableConfirm={isSubmitting}
      onSubmit={handleSubmit}
    >
      <div className="heading--extraTopMargin">
        {!showEmployeePicker ? (
          <FormattedMessage
            id="attendance.addModal.addAttendanceForEmployee"
            defaultMessage="Dodaj obecność dla {name}"
            values={{
              name: `${singleEmployeeFilter.first_name} ${singleEmployeeFilter.last_name}`,
            }}
          />
        ) : null}
        {!showDatePicker ? (
          <div className="k-createAttendanceModal__employeeInfo">
            {intl.formatMessage(messages.headerText, { date })}
          </div>
        ) : null}
        {showEmployeePicker ? (
          <MDSelectPopover
            name={intl.formatMessage(messages.chooseEmployee)}
            id="employee"
            defaultValue={selectedEmployee}
            options={relevantEmployees}
            onChange={handleEmployeeChange}
            closeOnClick
            withSearch
            yDropdownOffset={Y_OFFSET_EMPLOYEE_SELECT}
            wrapperClassName={isWeekView && 'k-createAttendanceModal__selectPopover'}
          />
        ) : null}

        <MDSelect
          name={intl.formatMessage(messages.chooseLocation)}
          id="location"
          defaultValue={selectedLocation}
          options={relevantLocations}
          onChange={handleLocationChange}
          closeOnClick
        />
        {showDatePicker ? (
          <MDDatesPicker
            id="attendanceDate"
            handleInputChange={e => setDate(...e.target.value)}
            values={[date]}
            singleDate
            label={intl.formatMessage(messages.date)}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            disabledDates={disabledDates}
          />
        ) : null}

        <MDTimeInput
          label={intl.formatMessage(messages.time)}
          value={hours}
          onChange={e => setHours(e.target.value)}
          id="time"
          modifiers={['modal']}
          short={false}
        />

        {showLabelsSelect && (
          <MultiSelectOptionManager
            options={labels}
            defaultOptions={[]}
            selectItemsId={setSelectedLabels}
            asInput
            hideAddAndEdit={disabledAddAndEditLabels}
            isModal
          />
        )}
      </div>
    </MDKadroModal>
  );
};

CreateAttendanceModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CreateAttendanceModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  ),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  createAttendance: PropTypes.func,
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
  showDatePicker: PropTypes.bool,
  showEmployeePicker: PropTypes.bool,
  singleEmployeeFilter: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  useMultipleLocationFilter: PropTypes.bool,
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company_id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  isLoanedEmployee: PropTypes.bool,
};

export default CreateAttendanceModal;
