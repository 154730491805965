import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import TopBarMultipleChoice from '@/components/TopBars/common/TopBarMultipleChoice/TopBarMultipleChoice.jsx';
import { employmentConditionsFilterOptions } from '@/constants/employmentConditions.js';

import { getSelectedEmploymentConditions } from './TopBarEmploymentConditionsFilter.heleprs';

const TopBarEmploymentConditionsFilter = ({
  employmentConditions,
  employmentConditionsFilter,
  hideFilter,
  changeEmploymentConditionsFilter,
  selectAllWhenEmpty,
}) => {
  const conditionsToChoose = useMemo(
    () => [...employmentConditionsFilterOptions, ...employmentConditions],
    [employmentConditionsFilterOptions, employmentConditions],
  );

  const selectedEmploymentConditions = useMemo(
    () => getSelectedEmploymentConditions(employmentConditionsFilter, selectAllWhenEmpty, conditionsToChoose),
    [employmentConditionsFilter, selectAllWhenEmpty, conditionsToChoose],
  );

  const [selected, setSelected] = useState(selectedEmploymentConditions);

  const handleChange = conditions => {
    const newConditions =
      employmentConditionsFilter.length === 0 || typeof employmentConditionsFilter[0] === 'object'
        ? conditions
        : conditions.map(({ id }) => id);
    changeEmploymentConditionsFilter(newConditions);
    setSelected(newConditions);
  };

  useEffect(() => {
    if (selectAllWhenEmpty) {
      changeEmploymentConditionsFilter(selected);
    }
  }, []);

  if (hideFilter) {
    return null;
  }

  return (
    <div className="k-topBarEmploymentConditionsFilter k-topBar__leftElement">
      <TopBarMultipleChoice
        filterName={
          <FormattedMessage id="topbar.employmentConditionsFilter.title" defaultMessage="Warunki zatrudnienia" />
        }
        objectsToChoose={conditionsToChoose}
        selectedObjects={selected}
        onChange={handleChange}
        icon={<Icon type="material-outlined" name="assignment" className="k-topBarChoice__selectedIcon" />}
        className="k-topBarChoice--employmentConditionsFilter"
        displayKey="name"
      />
    </div>
  );
};

TopBarEmploymentConditionsFilter.propTypes = {
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditionsFilter: PropTypes.arrayOf(PropTypes.shape({})),
  hideFilter: PropTypes.bool,
  changeEmploymentConditionsFilter: PropTypes.func,
  selectAllWhenEmpty: PropTypes.bool,
};

export default TopBarEmploymentConditionsFilter;
