import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import KadroCalendarPopoverInput from '../../inputs/KadroCalendarPopoverInput.jsx';
import WeekdayPicker from '../WeekdayPicker/WeekdayPicker.jsx';

const PeriodPicker = ({ calText, selectedRange, changeRange, minDate, maxDate, selectedWeekdays, changeWeekdays }) => (
  <div>
    <fieldset className="form-group">
      <label htmlFor="czas">
        <FormattedMessage id="common.period" defaultMessage="Okres" />
      </label>
      <KadroCalendarPopoverInput
        position="top"
        inputValue={calText}
        selectedRange={selectedRange}
        onRangeChange={changeRange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </fieldset>
    <WeekdayPicker selectedWeekdays={selectedWeekdays} changeWeekdays={changeWeekdays} />
  </div>
);

PeriodPicker.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PeriodPicker.propTypes = {
  calText: PropTypes.string,
  selectedRange: PropTypes.shape({}),
  selectedWeekdays: PropTypes.arrayOf(PropTypes.number),
  changeRange: PropTypes.func,
  changeWeekdays: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default PeriodPicker;
