import { defineMessages } from 'react-intl';

import {
  ABSENCES_EDIT_SHOW,
  COMPANY_MANAGE_LOCATIONS_SHOW_ASSIGN,
 } from '@/constants/Permissions';

export const ROLES = {
  COMPANY_MANAGE_LOCATIONS_EDIT: 'COMPANY_MANAGE_LOCATIONS:SHOW_EDIT',
  ROLE_USER: 'ROLE_USER',
  ROLE_MANAGER: 'ROLE_MANAGER',
};

export const OWNER = 'owner';
export const EMPLOYEE = 'employee';
export const MANAGER = 'manager';

export const rolesLangs = defineMessages({
  employee: {
    id: 'roles.role.employee',
    defaultMessage: 'Pracownik',
  },
  manager: {
    id: 'roles.role.manager',
    defaultMessage: 'Manager',
  },
});

export const permissionsLangs = defineMessages({
  'DASHBOARD_VIEW:SHOW': {
    id: 'roles.permission.dashboardView:show',
    defaultMessage: 'Dostęp do sekcji "Pulpit"',
  },
  'COMPANY_MANAGE_VIEW:SHOW': {
    id: 'roles.permission.companyManageView:show',
    defaultMessage: 'Dostęp do sekcji "Moja firma"',
  },
  'COMPANY_MANAGE_LOCATIONS:SHOW': {
    id: 'roles.permission.companyManageLocations:show',
    defaultMessage: 'Dostęp do zakładki "Lokalizacje ',
  },

  'COMPANY_MANAGE_LOCATIONS:SHOW_ADD': {
    id: 'roles.permission.companyManageLocations:showAdd',
    defaultMessage: 'Opcja - dodaj lokalizację ',
  },
  'COMPANY_MANAGE_LOCATIONS:SHOW_EDIT': {
    id: 'roles.permission.companyManageLocations:showEdit',
    defaultMessage: 'Opcja - edytuj lokalizację',
  },
  'COMPANY_MANAGE_LOCATIONS:SHOW_DELETE': {
    id: 'roles.permission.companyManageLocations:showDelete',
    defaultMessage: 'Opcja - usuń lokalizację ',
  },
  'COMPANY_MANAGE_JOBTITLES:SHOW': {
    id: 'roles.permission.companyManageJobtitles:show',
    defaultMessage: 'Dostęp do zakładki "Stanowiska"',
  },
  'COMPANY_MANAGE_JOBTITLES:SHOW_ADD': {
    id: 'roles.permission.companyManageJobtitles:showAdd',
    defaultMessage: 'Opcja - dodaj stanowisko"',
  },
  'COMPANY_MANAGE_JOBTITLES:SHOW_EDIT': {
    id: 'roles.permission.companyManageJobtitles:showEdit',
    defaultMessage: 'Opcja - edytuj stanowisko"',
  },
  'COMPANY_MANAGE_JOBTITLES:SHOW_DELETE': {
    id: 'roles.permission.companyManageJobtitles:showDelete',
    defaultMessage: 'Opcja - usuń stanowisko',
  },
  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW': {
    id: 'roles.permission.companyManageShiftblocks:show',
    defaultMessage: 'Dostęp do zakładki "Godziny pracy"',
  },
  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW_EDIT': {
    id: 'roles.permission.companyManageShiftblocks:showEdit',
    defaultMessage: 'Opcja - edytuj godziny pracy',
  },
  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW_DELETE': {
    id: 'roles.permission.companyManageShiftblocks:showDelete',
    defaultMessage: 'Opcja - usuń godziny pracy',
  },
  'COMPANY_MANAGE_EMPLOYEES:SHOW': {
    id: 'roles.permission.companyManageEmployees:show',
    defaultMessage: 'Dostęp do zakładki "Pracownicy"',
  },
  'COMPANY_MANAGE_EMPLOYEES:SHOW_ADD': {
    id: 'roles.permission.companyManageEmployees:showAdd',
    defaultMessage: 'Opcja - dodaj pracownika',
  },
  'COMPANY_MANAGE_EMPLOYEES:SHOW_EDIT': {
    id: 'roles.permission.companyManageEmployees:showEdit',
    defaultMessage: 'Opcja - edytuj profil pracownika',
  },
  'COMPANY_MANAGE_EMPLOYEES:SHOW_DELETE': {
    id: 'roles.permission.companyManageEmployees:showDelete',
    defaultMessage: 'Opcja - usuń profil pracownika',
  },
  [COMPANY_MANAGE_LOCATIONS_SHOW_ASSIGN]: {
    id: 'roles.permission.companyManageLocationsShowAssign',
    defaultMessage: 'Opcja - edytuj lokalizacje pracownika',
  },
  'SUPPLEMENTARY_EMPLOYEES:MODIFY': {
    id: 'roles.permission.supplementaryEmployees:modify',
    defaultMessage: 'Dostęp do pracowników pomocniczych',
  },
  'INACTIVE_EMPLOYEES:MODIFY': {
    id: 'roles.permission.inactiveEmployees:modify',
    defaultMessage: 'Dostęp do pracowników nieaktywnych',
  },
  'COMPANY_MANAGE_CONDITIONS:SHOW': {
    id: 'roles.permission.companyManageConditions:show',
    defaultMessage: 'Dostęp do zakładki "Warunki zatrudnienia"',
  },
  'COMPANY_MANAGE_CONDITIONS:SHOW_EDIT': {
    id: 'roles.permission.companyManageConditions:showEdit',
    defaultMessage: 'Opcja - edytuj warunek zatrudnienia',
  },
  'COMPANY_MANAGE_CONDITIONS:SHOW_DELETE': {
    id: 'roles.permission.companyManageConditions:showDelete',
    defaultMessage: 'Opcja - usuń warunek zatrudnienia',
  },
  'COMPANY_MANAGE_AVAILABILITIES:SHOW': {
    id: 'roles.permission.companyManageAvailabilities:show',
    defaultMessage: 'Dostęp do zakładki "Typy dostępności"',
  },
  'COMPANY_MANAGE_AVAILABILITIES:SHOW_EDIT': {
    id: 'roles.permission.companyManageAvailabilities:showEdit',
    defaultMessage: 'Opcja - edytuj typ dostepności',
  },
  'COMPANY_MANAGE_AVAILABILITIES:SHOW_DELETE': {
    id: 'roles.permission.companyManageAvailabilities:showDelete',
    defaultMessage: 'Opcja - usuń typ dostępności',
  },
  'COMPANY_MANAGE_PERMISSIONS:SHOW': {
    id: 'roles.permission.companyManagePermissions:show',
    defaultMessage: 'Dostęp do zakładki "Role"',
  },
  'COMPANY_MANAGE_ABSENCE_TYPES:SHOW': {
    id: 'roles.permission.absenceTypes:show',
    defaultMessage: 'Dostęp do zakładki "Typy urlopów"',
  },
  'COMPANY_MANAGE_DEVICES:SHOW': {
    id: 'roles.permission.devices:show',
    defaultMessage: 'Dostęp do zakładki "Urządzenia"',
  },
  'SETTINGS_VIEW:SHOW': {
    id: 'roles.permission.settingsView:show',
    defaultMessage: 'Dostęp do zakładki "Ustawienia lokalizacji"',
  },
  'SCHEDULE_VIEW:SHOW': {
    id: 'roles.permission.scheduleView:show',
    defaultMessage: 'Dostęp do sekcji "Grafik pracy"',
  },
  'SCHEDULE_EDIT:ENABLE': {
    id: 'roles.permission.scheduleEdit:enable',
    defaultMessage: 'Możliwość edycji grafiku pracy',
  },
  'BUDGET_INFO:SHOW': {
    id: 'roles.permission.budgetInfo:show',
    defaultMessage: 'Dostęp do opcji "Budżet"',
  },
  'BUDGET_INFO:EDIT': {
    id: 'roles.permission.budgetInfo:edit',
    defaultMessage: 'Edycja danych w sekcji "Budżet"',
  },
  'SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES:ADD': {
    id: 'roles.permission.scheduleViewSupplementaryEmployees:add',
    defaultMessage: 'Możliwość edycji grafiku pracy dla pracowników pomocniczych',
  },
  'ATTENDANCE_VIEW:SHOW': {
    id: 'roles.permission.attendanceView:show',
    defaultMessage: 'Dostęp do sekcji "Lista obecności"',
  },
  MANAGER_EDIT_OWN_ATTENDANCES: {
    id: 'roles.permission.managerEditOwnAttendances:get',
    defaultMessage: 'Możliwość edycji własnych obecności',
  },
  'ATTENDANCE_EDIT_OTHERS:ENABLE': {
    id: 'roles.permission.EditAttendances:show',
    defaultMessage: 'Możliwość edycji obecności pozostałych',
  },
  'PAYROLL_LOCATION_VIEW:SHOW': {
    id: 'roles.permission.payrollLocationView:show',
    defaultMessage: 'Dostęp do sekcji "Ewidencja czasu pracy"',
  },
  'EXPORT_VIEW:GET': {
    id: 'roles.permission.exportsView:show',
    defaultMessage: 'Dostęp do sekcji "Eksporty"',
  },
  'REPORTS_VIEW:SHOW': {
    id: 'roles.permission.reportsView:show',
    defaultMessage: 'Dostęp do sekcji "Raporty"',
  },
  'PAYROLL_SETTINGS_EDIT:ENABLE': {
    id: 'roles.permission.payrollSettingsEdit:enable',
    defaultMessage: 'Opcja - Ustawienia rozliczeń',
  },
  'ABSENCES_LIMIT_VIEW:SHOW': {
    id: 'roles.permission.absencesLimitView:show',
    defaultMessage: 'Dostęp do widoku "Widok Limitów"',
  },
  'ABSENCES_EDIT:SHOW': {
    id: 'roles.permission.absencesEditShow',
    defaultMessage: 'Możliwość akceptacji wniosków urlopowych',
  },
  'ABSENCES_LEAVE_REQUEST_VIEW:SHOW': {
    id: 'roles.permission.absencesLeaveRequestView:show',
    defaultMessage: 'Dostęp do widoku "Widok Wniosków"',
  },
  'ABSENCES_WITH_EQUAL_RANG_VIEW:SHOW': {
    id: 'roles.permission.absencesWithEqualRangView:show',
    defaultMessage: 'Podgląd urlopów dla równej lub wyższej rangi',
  },
  'COMPANY_MANAGE_EMPLOYEES_LIMITS:SHOW_EDIT': {
    id: 'roles.permission.companyManageEmployeesLimits:showEdit',
    defaultMessage: 'Dostęp do edycji limitów urlopowych',
  },
  'ABSENCES_FOR_OTHERS:SHOW_ADD': {
    id: 'roles.permission.absencesForOthers:showAdd',
    defaultMessage: 'Użytkownik może tworzyć wnioski urlopowe dla innych pracowników',
  },
  'EMPLOYEE_SCHEDULE_EDIT:ENABLE': {
    id: 'roles.permission.employeeScheduleEdit:enable',
    defaultMessage: 'Użytkownik może tworzyć, edytować i usuwać własne zmiany',
  },
  'ATTENDANCE_EDIT_HIGHER_EQUAL:ENABLE': {
    id: 'roles.permission.attendanceEditHigherEqual:enable',
    defaultMessage: 'Możliwość edycji obecności osób z wyższą lub równą rangą',
  },
  'ATTENDANCE_LABELS_MANAGE:SHOW': {
    id: 'roles.permission.attendanceLabelsManage',
    defaultMessage: 'Możliwość tworzenia, edycji oraz usuwania typów etykiet',
  },
  'EMPLOYEE_PAYROLL_VIEW:SHOW': {
    id: 'roles.permission.employeePayrollView:show',
    defaultMessage: 'Dostęp do własnej ewidencji czasu pracy',
  },
});

export const permissionsDescriptionLangs = defineMessages({
  'COMPANY_MANAGE_EMPLOYEES_LIMITS:SHOW_EDIT': {
    id: 'roles.permission.companyManageEmployeesLimits:showEdit.description',
    defaultMessage:
      'Opcja daje dostęp do zakładki "Limity urlopów"  w oknie edycji profilu pracownika.  {clickHere}, aby dowiedzieć się więcej',
  },
  'ABSENCES_WITH_EQUAL_RANG_VIEW:SHOW': {
    id: 'roles.permission.absencesWithEqualRangView:showEdit.description',
    defaultMessage:
      'Opcja ta, gdy jest wyłączona, ukrywa wszystkie wnioski urlopowe oraz limity urlopowe użytkowników o równej lub wyższej randze. {clickHere}, aby dowiedzieć się więcej na temat rang.',
  },
  'PAYROLL_SETTINGS_EDIT:ENABLE': {
    id: 'roles.permission.payrollSettingsEdit:showEdit.description',
    defaultMessage:
      'Opcja daje dostęp do edycji ustawień rozliczeń w Ewidencji czasu pracy. Ustawienia te wpływają między innymi na zaokrąglenia, czasy tolerancji wczesnych przyjść, określenie godzin nocnych itp. {clickHere}, aby dowiedzieć się więcej na temat tej opcji.',
  },
  'ATTENDANCE_VIEW:SHOW': {
    id: 'roles.permission.attendanceView:showEdit.description',
    defaultMessage: 'Opcja daje dostęp do sekcji oraz możliwość dodawania oraz edycji obecności pracowników',
  },
  'SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES:ADD': {
    id: 'roles.permission.scheduleViewSupplementaryEmployees:showEdit.description',
    defaultMessage: 'Opcja umożliwia dodawania zmian w grafiku dla "pracowników pomocnicznych"',
  },
  'SCHEDULE_VIEW:SHOW': {
    id: 'roles.permission.scheduleView:showEdit.description',
    defaultMessage: 'Opcja daje dostęp do sekcji oraz możliwość dodawania oraz edycji zmian w grafiku pracy',
  },
  'BUDGET_INFO:SHOW': {
    id: 'roles.permission.budgetInfo:showEdit.description',
    defaultMessage:
      'Opcja daje dostęp do podglądu stawek pracowników w sekcji Grafik pracy, "prognozowanego budżetu" oraz "limitów budżetu". {clickHere}, aby dowiedzieć się więcej.',
  },
  'BUDGET_INFO:EDIT': {
    id: 'roles.permission.budgetInfo:edit.description',
    defaultMessage:
      'Opcja daje dostęp do modyfikowania wartości "prognozowanego budżetu" oraz "limitów budżetu". {clickHere}, aby dowiedzieć się więcej.',
  },
  'SCHEDULE_EDIT:ENABLE': {
    id: 'roles.permission.scheduleEdit:showEdit.description',
    defaultMessage: 'Gdy opcja jest wyłączona, użytkownik ma dostęp do podlądu grafiku pracy ale nie może go edytować',
  },
  'COMPANY_MANAGE_VIEW:SHOW': {
    id: 'roles.permission.companyManageView:showEdit.description',
    defaultMessage: 'Opcja daje dostęp do sekcji oraz zawartych w nim wszystkich zakładek',
  },
  'COMPANY_MANAGE_AVAILABILITIES:SHOW': {
    id: 'roles.permission.companyManageAvailabilities:show.description',
    defaultMessage: 'Opcja daje dostęp do sekcji, a także możliwość dodawania oraz edycji typów dostępności',
  },
  'COMPANY_MANAGE_EMPLOYEES:SHOW': {
    id: 'roles.permission.companyManageEmployees:show.description',
    defaultMessage:
      'Opcja daje dostęp do zakładki, a także możliwość dodawania oraz edycji i usuwania profilów pracowników',
  },
  'COMPANY_MANAGE_LOCATIONS:SHOW': {
    id: 'roles.permission.companyManageLocations:showEdit.description',
    defaultMessage: 'Opcja daje dostęp do zakładki, a także możliwość dodawania oraz edycji ustawień lokalizacji',
  },
  'COMPANY_MANAGE_JOBTITLES:SHOW': {
    id: 'roles.permission.companyManageJobtitles:showEdit.description',
    defaultMessage:
      'Opcja daje dostęp do zakładki, a także możliwość dodawania oraz edycji i usuwania stanowisk.{clickHere}, aby dowiedzieć się więcej. ',
  },
  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW': {
    id: 'roles.permission.companyManageShiftblocks:description',
    defaultMessage:
      'Opcja daje dostęp do zakładki, a także możliwość dodawania oraz edycji i usuwania "godzin pracy".{clickHere}, aby dowiedzieć się więcej.',
  },
  'COMPANY_MANAGE_CONDITIONS:SHOW': {
    id: 'roles.permission.companyManageConditions:show.description',
    defaultMessage: 'Opcja daje dostęp do sekcji, a także możliwość dodawania oraz edycji typów warunków zatrudnienia',
  },
  'COMPANY_MANAGE_ABSENCE_TYPES:SHOW': {
    id: 'roles.permission.absenceTypes:description',
    defaultMessage:
      'Opcja daje dostęp do zakładki "Typy urlopów" w sekcji "Moja firma". {clickHere}, aby dowiedzieć się więcej',
  },
  'ATTENDANCE_EDIT_HIGHER_EQUAL:ENABLE': {
    id: 'roles.permission.attendanceEditHigherEqual:description',
    defaultMessage:
      'Opcja ta, gdy jest wyłączona, nie pozwala na edycję obecności użytkowników o równej lub wyższej randze.',
  },
  'ATTENDANCE_LABELS_MANAGE:SHOW': {
    id: 'roles.permission.attendanceLabelsManage.description',
    defaultMessage: 'Opcja ta umożliwia tworzenie, edycje oraz usuwanie typów etykiet z poziomu okna edycji obecności.',
  },
  'EMPLOYEE_PAYROLL_VIEW:SHOW': {
    id: 'roles.permission.employeePayrollView:show.description',
    defaultMessage:
      'Opcja ta daje pracownikom wgląd w swoją Ewidencję Godzin. Jest dostępna tylko, jeśli została wcześniej włączona w ustawieniach firmy przez Administratora konta.',
  },
});

export const permissionsDescriptionLinks = defineMessages({
  'COMPANY_MANAGE_EMPLOYEES_LIMITS:SHOW_EDIT': {
    id: 'roles.permission.companyManageEmployeesLimits:showEdit.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/4805287-okreslanie-limitow-urlopowych-pracownika',
  },
  'ABSENCES_WITH_EQUAL_RANG_VIEW:SHOW': {
    id: 'roles.permission.absencesWithEqualRangView:showEdit.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/4784077-rangi-ustawianie-hierarchii-uprawnien-pracownikow',
  },
  'PAYROLL_SETTINGS_EDIT:ENABLE': {
    id: 'roles.permission.payrollSettingsEdit:showEdit.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen',
  },
  'BUDGET_INFO:SHOW': {
    id: 'roles.permission.budgetInfo:showEdit.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/2630464-prognozowanie-sprzedazy-i-koszty-pracownicze',
  },
  'BUDGET_INFO:EDIT': {
    id: 'roles.permission.budgetInfo:edit.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/2630464-prognozowanie-sprzedazy-i-koszty-pracownicze',
  },
  'COMPANY_MANAGE_JOBTITLES:SHOW': {
    id: 'roles.permission.companyManageJobtitles:showEdit.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/2108484-stanowiska',
  },
  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW': {
    id: 'roles.permission.companyManageShiftblocks:descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/2108522-godziny-pracy',
  },
  'COMPANY_MANAGE_ABSENCE_TYPES:SHOW': {
    id: 'roles.permission.absenceTypes:descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/4799934-definiowanie-wlasnych-typow-urlopow',
  },
  'EMPLOYEE_PAYROLL_VIEW:SHOW': {
    id: 'roles.permission.employeePayrollView:show.descriptionLink',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/4799934-definiowanie-wlasnych-typow-urlopow',
  },
});

export const permissionsGroupLangs = defineMessages({
  SETTINGS: {
    id: 'roles.group.settings',
    defaultMessage: 'Sekcja - Ustawienia',
  },
  DASHBOARD: {
    id: 'roles.group.dashboard',
    defaultMessage: 'Sekcja - Pulpit',
  },
  ACCOUNT: {
    id: 'roles.group.account',
    defaultMessage: 'Sekcja - Moja firma',
  },
  SCHEDULE_PLANNING: {
    id: 'roles.group.schedulePlanning',
    defaultMessage: 'Sekcja - Grafik pracy',
  },
  AVA_LIST: {
    id: 'roles.group.avaList',
    defaultMessage: 'Sekcja - Lista obecności',
  },
  PAYROLL: {
    id: 'roles.group.payroll',
    defaultMessage: 'Rozliczenia i ewidencja godzin',
  },
  ABSENCES: {
    id: 'roles.group.absences',
    defaultMessage: 'Urlopy i delegacje',
  },
  EMPLOYEE_TIMESHEET: {
    id: 'roles.group.employeeTimesheet',
    defaultMessage: 'Rejestr godzin',
  },
});

export const permissionsOrder = [
  'ABSENCES_LIMIT_VIEW:SHOW',

  'ABSENCES_LEAVE_REQUEST_VIEW:SHOW',

  [ABSENCES_EDIT_SHOW],

  'COMPANY_MANAGE_EMPLOYEES_LIMITS:SHOW_EDIT',

  'ABSENCES_WITH_EQUAL_RANG_VIEW:SHOW',

  [ABSENCES_EDIT_SHOW],

  'COMPANY_MANAGE_EMPLOYEES_LIMITS:SHOW_EDIT',

  'ABSENCES_FOR_OTHERS:SHOW_ADD',

  'PAYROLL_VIEW:SHOW',

  'REPORTS_VIEW:SHOW',

  'PAYROLL_LOCATION_VIEW:SHOW',

  'PAYROLL_SETTINGS_EDIT:ENABLE',

  'EMPLOYEE_PAYROLL_VIEW:SHOW',

  'MANAGER_EDIT_OWN_ATTENDANCES',

  'ATTENDANCE_VIEW:SHOW',

  'ATTENDANCE_EDIT_OTHERS:ENABLE',

  'ATTENDANCE_LABELS_MANAGE:SHOW',
  'ATTENDANCE_VIEW:SHOW',

  'SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES:ADD',

  'SCHEDULE_VIEW:SHOW',

  'BUDGET_INFO:SHOW',

  'BUDGET_INFO:EDIT',

  'SCHEDULE_EDIT:ENABLE',

  'COMPANY_MANAGE_VIEW:SHOW',

  'COMPANY_MANAGE_LOCATIONS:SHOW',

  'COMPANY_MANAGE_LOCATIONS:SHOW_ADD',

  'COMPANY_MANAGE_LOCATIONS:SHOW_EDIT',

  'COMPANY_MANAGE_LOCATIONS:SHOW_DELETE',

  'COMPANY_MANAGE_JOBTITLES:SHOW',

  'COMPANY_MANAGE_JOBTITLES:SHOW_ADD',

  'COMPANY_MANAGE_JOBTITLES:SHOW_EDIT',

  'COMPANY_MANAGE_JOBTITLES:SHOW_DELETE',

  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW',

  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW_EDIT',

  'COMPANY_MANAGE_SHIFTBLOCKS:SHOW_DELETE',

  'COMPANY_MANAGE_EMPLOYEES:SHOW',

  'COMPANY_MANAGE_EMPLOYEES:SHOW_ADD',

  'COMPANY_MANAGE_EMPLOYEES:SHOW_EDIT',

  'COMPANY_MANAGE_EMPLOYEES:SHOW_DELETE',

  [COMPANY_MANAGE_LOCATIONS_SHOW_ASSIGN],

  'COMPANY_MANAGE_EMPLOYEES:SHOW_DELETE',

  'SUPPLEMENTARY_EMPLOYEES:MODIFY',

  'INACTIVE_EMPLOYEES:MODIFY',

  'COMPANY_MANAGE_CONDITIONS:SHOW',

  'COMPANY_MANAGE_CONDITIONS:SHOW_EDIT',

  'COMPANY_MANAGE_CONDITIONS:SHOW_DELETE',

  'COMPANY_MANAGE_AVAILABILITIES:SHOW',

  'COMPANY_MANAGE_AVAILABILITIES:SHOW_EDIT',

  'COMPANY_MANAGE_AVAILABILITIES:SHOW_DELETE',

  'COMPANY_MANAGE_PERMISSIONS:SHOW',

  'COMPANY_MANAGE_ABSENCE_TYPES:SHOW',

  'COMPANY_MANAGE_DEVICES:SHOW',

  'DASHBOARD_VIEW:SHOW',

  'SETTINGS_VIEW:SHOW',

  'EMPLOYEE_SCHEDULE_EDIT:ENABLE',
].reduce((permissions, permission, index) => ({ ...permissions, [permission]: index }));

export const PERMISSIONS_TO_EDIT_ATTENDANCE = [
  'ATTENDANCE_EDIT_HIGHER_EQUAL:ENABLE',
  'MANAGER_EDIT_OWN_ATTENDANCES',
  'ATTENDANCE_EDIT_OTHERS:ENABLE',
];

export const ATTENDANCE_LABELS_MANAGE_SHOW = 'ATTENDANCE_LABELS_MANAGE:SHOW';
