import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import { sortByMultipleKeys } from '@/components/newPayrollViews/columns.helpers';
import { tabsMessages } from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTable.messages';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { useTableWithScroll } from '@/hooks';
import { filterEmployeesByFilters } from '@/utils/userEmployeesHelpers';

import PayrollLocationButtonBar from './PayrollLocationButtonBar/PayrollLocationButtonBar.redux';
import { getColumns, getPayrollLocationColumns, getTableData, TABS } from './PayrollLocationTable.helpers';
import PayrollLocationTableEmpty from './PayrollLocationTableEmpty/PayrollLocationTableEmpty';
import PayrollLocationTableOptions from './PayrollLocationTableOptions/PayrollLocationTableOptions.redux';

import './PayrollLocationTable.scss';

const PayrollLocationTable = (props, { intl }) => {
  const {
    data,
    visibleColumns,
    timeFormatType,
    sortingUseLastName,
    overtimeStats,
    userPermissions,
    dateFilter,
    redirectToPayroll,
    payoutSettingType,
    userEmployees,
    selectedLocationIds,
    selectedEmploymentConditions,
    selectedJobTitles,
    companySettings,
    getPayrollLocationViewData,
    showSkeletons,
    contracts,
  } = props;
  const tabs = useMemo(() => {
    if (!companySettings.payroll_approval_enabled) {
      return [];
    }
    return TABS.map(tab => {
      const numberOfItems = tab.id === 'all' ? data.length : data.filter(row => row.status === tab.id).length;

      return {
        ...tab,
        label: intl.formatMessage(tabsMessages[tab.id]),
        onSelect: () => {
          const allSelected = tab.id === 'all';
          setActiveTab(tab.id);
          getPayrollLocationViewData(allSelected ? null : tab.id, { staleWhileRevalidate: true });
        },
        numberOfItems,
      };
    });
  }, [intl, getPayrollLocationViewData, data]);
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);
  const [sortBy, setSortBy] = useState('employee');
  const [sortDesc, setSortDesc] = useState(false);

  const selectedEmploymentConditionIds = useMemo(() => selectedEmploymentConditions.map(({ id }) => id), [
    selectedEmploymentConditions,
  ]);
  const selectedJobTitleIds = useMemo(() => selectedJobTitles.map(({ id }) => id), [selectedJobTitles]);
  const filteredEmployees = useMemo(
    () =>
      filterEmployeesByFilters(
        userEmployees,
        selectedLocationIds,
        selectedJobTitleIds,
        selectedEmploymentConditionIds,
        contracts,
        dateFilter.start,
        dateFilter.end,
      ),
    [userEmployees, selectedLocationIds, selectedJobTitleIds, selectedEmploymentConditionIds],
  );
  const { isTableWithScroll } = useTableWithScroll(visibleColumns, [], { checkboxColumnWidth: 0 });
  const allColumns = getPayrollLocationColumns(userPermissions, payoutSettingType, companySettings);
  const columns = useMemo(() => getColumns(allColumns, visibleColumns, isTableWithScroll, intl), [
    visibleColumns,
    isTableWithScroll,
    allColumns,
  ]);
  const tableData = useMemo(() => {
    const filteredData =
      activeTab === 'all' || tabs.length === 0 ? data : data.filter(item => item.status === activeTab);
    return getTableData(
      filteredData,
      timeFormatType,
      sortingUseLastName,
      overtimeStats,
      intl,
      dateFilter,
      showSkeletons,
      filteredEmployees.length,
    );
  }, [
    data,
    timeFormatType,
    sortingUseLastName,
    overtimeStats,
    dateFilter,
    showSkeletons,
    filteredEmployees,
    activeTab,
  ]);
  const maxHeight = window.innerHeight - 180;
  const disableSelectAllCheckbox = dateFilter.end < PAYROLL_APPROVAL_START_DATE;
  const onRowClick = row => {
    redirectToPayroll(row.employee_id);
  };

  const onSort = useCallback(
    sortColumns => {
      if (sortColumns.length === 0) {
        return;
      }
      const [{ id, desc }] = sortColumns;
      const column = columns.find(c => c.id === id);
      if (column) {
        setSortBy(column.accessor);
        setSortDesc(desc);
      }
    },
    [columns],
  );

  const sortedTableData = useMemo(
    () => sortByMultipleKeys(tableData, [sortBy, 'employee'], { desc: sortDesc, timeFormatType }, columns),
    [tableData, sortBy, sortDesc, columns],
  );

  const getRowId = useCallback(row => row.employee_id, []);

  return (
    <MDTable
      tabs={tabs}
      activeTab={activeTab}
      data={sortedTableData}
      columns={columns}
      withSearch={false}
      tableEmpty={
        <PayrollLocationTableEmpty
          hasTabs={companySettings.payroll_approval_enabled}
          numberOfFetchedItems={data.length}
        />
      }
      options={<PayrollLocationTableOptions />}
      tableOptions={<PayrollLocationButtonBar />}
      withHorizontalScroll={isTableWithScroll}
      maxHeight={maxHeight}
      withCheckbox={companySettings.payroll_approval_enabled}
      disableSelectAllCheckbox={disableSelectAllCheckbox}
      onRowClick={!showSkeletons ? onRowClick : undefined}
      defaultSortByColumn="employee"
      withShadowOnStickyColumns
      showSkeletons={showSkeletons}
      onSort={onSort}
      getRowId={getRowId}
    />
  );
};

PayrollLocationTable.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollLocationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({})),
  timeFormatType: PropTypes.string,
  sortingUseLastName: PropTypes.bool,
  overtimeStats: PropTypes.shape({}),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  dateFilter: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  redirectToPayroll: PropTypes.func,
  payoutSettingType: PropTypes.string,
  companySettings: PropTypes.shape({
    payroll_approval_enabled: PropTypes.bool,
  }),
  showSkeletons: PropTypes.bool,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  selectedLocationIds: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  selectedEmploymentConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  getPayrollLocationViewData: PropTypes.func,
};

export default PayrollLocationTable;
