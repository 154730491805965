import { isEmpty } from 'lodash';

import { employmentConditionsFilterOptions } from '@/constants/employmentConditions';
import { EXPORT_STEPS, EXPORTS_FILTERS } from '@/constants/exportConstants';
import { getJobTitleIdsFromContracts, getRelevantContractsForMultipleDates } from '@/utils/contracts';
import { setCookie } from '@/utils/cookieHandlers';
import { getFromToFromDateStore, getRangeBetweenDates } from '@/utils/dateHelper';

import { formatAdditionalOptions } from '../exports.helpers';
import {
  ExportConfigurationStep,
  ExportEmployeesStep,
  ExportFileFormatSelectStep,
  ExportFiltersStep,
} from '../NewExportModalSteps';

export const MODAL_MODIFIERS = ['narrow'];

export const DEFAULT_EXPORTS_STEPS_COUNT = 2;

export const getInitialState = props => {
  const { dateArray } = props.mainDateStore;
  const [start, end] = getFromToFromDateStore({ dateArray });
  return {
    modalExportId: props.modalObject?.preselectedExport || '',
    selectedRange: { start, end },
    options: {},
  };
};

export const saveDataInBrowser = (state, modalObject) => {
  const selectedFormat = modalObject?.formats?.find(({ exportId }) => exportId === state.modalExportId);
  if (!selectedFormat) return;
  selectedFormat.options.forEach(option => {
    if (!option.cookieName) return;
    setCookie(option.cookieName, JSON.stringify(state.options[option.id]), 30);
  });
};

export const saveFiltersInBrowser = (
  jobTitlesItems,
  locationsItems,
  employmentConditionsItems,
  modalExportId,
  selectedRange,
  options,
  absenceTypes,
  columns,
  userId,
) => {
  const dataFromLocalStorage = JSON.parse(localStorage.getItem(EXPORTS_FILTERS));

  const filters = {
    selectedRange,
    jobTitles: jobTitlesItems,
    locations: locationsItems,
    employmentConditions: employmentConditionsItems,
  };

  const additionalOptions = {
    options,
    absenceTypes,
    columns,
  };

  localStorage.setItem(
    EXPORTS_FILTERS,
    JSON.stringify({
      ...(dataFromLocalStorage || {}),
      [userId]: {
        ...(dataFromLocalStorage?.[userId] || {}),
        [modalExportId]: {
          ...(dataFromLocalStorage?.[userId]?.[modalExportId] || {}),
          ...additionalOptions,
          ...filters,
        },
      },
    }),
  );
};

export const canContinue = (currentStep, state, multiSelects, formats = []) => {
  const steps = formats.find(({ exportId }) => exportId === state.modalExportId)?.steps || [
    EXPORT_STEPS.SELECTING_FORMAT,
  ];
  const stepName = steps[currentStep - 1];
  switch (stepName) {
    case EXPORT_STEPS.SELECTING_FORMAT:
      return !!state.modalExportId;
    case EXPORT_STEPS.FILTERING:
      return (
        multiSelects.jobTitlesItems.some(({ active }) => active) &&
        multiSelects.locationsItems.some(({ active }) => active) &&
        multiSelects.employmentConditionsItems.some(({ active }) => active)
      );
    case EXPORT_STEPS.CHOOSING_EMPLOYEES:
      return multiSelects.employeesItems.some(({ active }) => active);
    case EXPORT_STEPS.SETTINGS_OPTIONS:
      return !(state.modalExportId === 'f-enova-xml' && !Object.values(state.options).some(option => option));

    default:
      return true;
  }
};

export const getJobTitlesOptions = (jobTitles, locations, userJobTitles) =>
  jobTitles.reduce((options, { id, title }) => {
    const isActive = locations.some(({ active, value }) => active && userJobTitles[value]?.includes(id));
    if (isActive) {
      options.push({ value: id, label: title, active: true });
    }
    return options;
  }, []);

export const getLocationsOptions = (locations, active = true) =>
  locations.map(location => ({
    label: location.name,
    value: location.id,
    active,
  }));

export const getNewRelevantOptions = (options, optionsFromLocalStorage) =>
  options.reduce((acc, option) => {
    if (!optionsFromLocalStorage.some(({ value }) => value === option.id)) acc.push(option);
    return acc;
  }, []);

export const getRelevantLocationsOptions = (userLocations, locationsFromLocalStorage) => {
  const newLocations = getNewRelevantOptions(userLocations, locationsFromLocalStorage);

  const parsedNewLocations = getLocationsOptions(newLocations, false);

  return locationsFromLocalStorage.concat(parsedNewLocations);
};

export const getEmploymentConditionsOptions = employmentConditions =>
  [...employmentConditions, ...employmentConditionsFilterOptions].map(condition => ({
    label: condition.name,
    value: condition.id,
    active: true,
  }));

export const parsedEmploymentConditionsOptions = (employmentConditions, active = true) =>
  employmentConditions.map(location => ({
    label: location.name,
    value: location.id,
    active,
  }));

export const getRelevantEmploymentConditionsOptions = (employmentConditions, employmentConditionsFromLocalStorage) => {
  const mergedEmploymentConditionsFilter = [...employmentConditions, ...employmentConditionsFilterOptions];

  const newEmploymentConditions = getNewRelevantOptions(
    mergedEmploymentConditionsFilter,
    employmentConditionsFromLocalStorage,
  );

  const parsedNewEmploymentConditions = parsedEmploymentConditionsOptions(newEmploymentConditions, false);

  return employmentConditionsFromLocalStorage.concat(parsedNewEmploymentConditions);
};

export const getEmployeesOptions = userEmployees =>
  userEmployees.map(employee => ({
    label: `${employee.first_name} ${employee.last_name}`,
    value: employee.id,
    active: true,
  }));

export const getAbsenceTypesOptions = absenceTypes =>
  absenceTypes.map(absenceType => ({
    label: absenceType.name,
    value: absenceType.id,
    active: false,
  }));

const getSelectedItemsIds = items => items.reduce((prev, curr) => (curr.active ? [...prev, curr.value] : prev), []);

export const getFilteredEmployeesOptions = (multiSelects, userEmployees, contracts, selectedRange) => {
  const locationsIds = getSelectedItemsIds(multiSelects.locationsItems);
  const jobTitlesIds = getSelectedItemsIds(multiSelects.jobTitlesItems);
  const conditionsIds = getSelectedItemsIds(multiSelects.employmentConditionsItems);
  const relevantEmployees = userEmployees.filter(e => {
    const employeeContracts = contracts[e.id] || [];
    const relevantContracts = getRelevantContractsForMultipleDates(
      employeeContracts,
      selectedRange.start,
      selectedRange.end,
    );
    const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
    const isEmployeeEmploymentConditionSelected = conditionsIds.includes(e.employment_conditions.template_id);
    const isEmployeeJobTitleSelected = employeeJobTitleIds.some(jobTitleId => jobTitlesIds.includes(jobTitleId));
    const isEmployeeLocationSelected = e.locations.some(loc => locationsIds.includes(loc.id));
    return isEmployeeJobTitleSelected && isEmployeeEmploymentConditionSelected && isEmployeeLocationSelected;
  });
  return getEmployeesOptions(relevantEmployees);
};

export const getExportPayload = (state, multiSelects, { absenceTypes }, multiSelectOptions, fileIconText) => {
  const { modalExportId } = state;
  const additionalOptions = formatAdditionalOptions(multiSelectOptions, multiSelects, absenceTypes, modalExportId);

  return {
    from: state.selectedRange.start,
    to: state.selectedRange.end,
    dateArray: getRangeBetweenDates(state.selectedRange.start, state.selectedRange.end),
    locations: getSelectedItemsIds(multiSelects.locationsItems),
    job_titles: getSelectedItemsIds(multiSelects.jobTitlesItems),
    extra_job_titles: [],
    employment_conditions_ids: getSelectedItemsIds(multiSelects.employmentConditionsItems),
    employees: getSelectedItemsIds(multiSelects.employeesItems),
    options: { ...state.options, ...additionalOptions },
    fileIconText,
  };
};

export const displayRelevantStep = (props, state, handlers, currentStep, multiSelects) => {
  if (isEmpty(props.modalObject)) return null;
  const selectedFormat = props.modalObject.formats.find(({ exportId }) => exportId === state.modalExportId);
  const steps = selectedFormat?.steps?.length > 0 ? selectedFormat.steps : [EXPORT_STEPS.SELECTING_FORMAT];
  const stepName = steps[currentStep - 1];

  switch (stepName) {
    case EXPORT_STEPS.SELECTING_FORMAT:
      return (
        <ExportFileFormatSelectStep
          formats={props.modalObject.formats}
          selectedFormat={state.modalExportId}
          handlers={handlers}
        />
      );
    case EXPORT_STEPS.FILTERING:
      return (
        <ExportFiltersStep
          selectedRange={state.selectedRange}
          handlers={handlers}
          multiSelects={multiSelects}
          mainDateStore={props.mainDateStore}
        />
      );
    case EXPORT_STEPS.CHOOSING_EMPLOYEES:
      return (
        <ExportEmployeesStep
          employeesItems={multiSelects.employeesItems}
          employeesHandlers={multiSelects.employeesHandlers}
        />
      );
    case EXPORT_STEPS.SETTINGS_OPTIONS: {
      return (
        <ExportConfigurationStep
          options={state.options}
          exportOptions={selectedFormat.options}
          handlers={handlers}
          multiSelects={multiSelects}
        />
      );
    }
    default: {
      const CustomStepComponent = selectedFormat?.customSteps?.[stepName];
      if (!CustomStepComponent) return null;

      return (
        <CustomStepComponent
          state={state}
          selectedFormat={selectedFormat}
          handlers={handlers}
          multiSelects={multiSelects}
        />
      );
    }
  }
};
