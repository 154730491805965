import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  date: {
    id: 'reportsTable.date',
    defaultMessage: 'Data',
  },
  plannedMinutes: {
    id: 'reportsTable.plannedMinutes',
    defaultMessage: 'Planowany czas pracy [h]',
  },
  workedMinutes: {
    id: 'reportsTable.workedMinutes',
    defaultMessage: 'Realny czas pracy [h]',
  },
  differenceMinutes: {
    id: 'reportsTable.differenceMinutes',
    defaultMessage: 'Różnica [h]',
  },
  differencePercent: {
    id: 'reportsTable.differencePercent',
    defaultMessage: 'Różnica [%]',
  },
  employeesCount: {
    id: 'reportsTable.employeesCount',
    defaultMessage: 'Liczba pracowników',
  },
  frequency: {
    id: 'reportsTable.frequency',
    defaultMessage: 'Frekwencja [%]',
  },
  plannedLaborCost: {
    id: 'reportsTable.plannedLaborCost',
    defaultMessage: 'Planowany koszt pracy [PLN]',
  },
  actualLaborCost: {
    id: 'reportsTable.actualLaborCost',
    defaultMessage: 'Realny koszt pracy [PLN]',
  },
  differenceCost: {
    id: 'reportsTable.differenceCost',
    defaultMessage: 'Różnica [PLN]',
  },
  differenceCostPercent: {
    id: 'reportsTable.differenceCostPercent',
    defaultMessage: 'Różnica [%]',
  },
  absencesMinutes: {
    id: 'reportsTable.differenceCostPercent',
    defaultMessage: 'Absencje [h]',
  },
});
