import { deburr, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import TopBarMultipleChoice from '@/components/TopBars/common/TopBarMultipleChoice/TopBarMultipleChoice.jsx';
import { typesOfMultipleLocations } from '@/constants/locations.js';

import { getUpdatedLocations } from './TopBarMultipleLocationFilter.helpers';

const TopBarMultipleLocationFilter = ({
  userLocations,
  selectedLocations,
  changeFilter,
  locationType = typesOfMultipleLocations.id,
  selectAllWhenEmpty,
}) => {
  const selectedLocationIds = useMemo(
    () => (selectAllWhenEmpty && !selectedLocations.length ? userLocations.map(({ id }) => id) : selectedLocations),
    [selectAllWhenEmpty, selectedLocations, userLocations],
  );

  const [selected, setSelected] = useState(selectedLocationIds);

  const prevSelected = useRef(selectedLocations);

  useEffect(() => setSelected(selectedLocationIds), []);

  useEffect(() => {
    const newSelectedLocations = selectAllWhenEmpty ? selected : getUpdatedLocations(selected, locationType);

    if (!newSelectedLocations) return;
    setSelected(newSelectedLocations);
    changeFilter(newSelectedLocations);
  }, [locationType, selected, changeFilter, selectAllWhenEmpty]);

  const handleChange = locations => {
    const newLocations = locationType === typesOfMultipleLocations.object ? locations : locations.map(({ id }) => id);
    setSelected(newLocations);
  };

  const handleBlur = () => {
    const hasNewLocation = !selected.every(locId => prevSelected.current.includes(locId));
    const preventRequest = !hasNewLocation;
    prevSelected.current = selected;

    changeFilter(selected, preventRequest);
  };

  const sortedLocations = sortBy([...userLocations], location => deburr(location.name.toLowerCase()));
  const selectedObjects = useMemo(
    () => (locationType === typesOfMultipleLocations.object ? selected : selected.map(id => ({ id }))),
    [selectedLocations, selected],
  );

  const filterName = useMemo(() => {
    if (selected.length !== 1)
      return <FormattedMessage id="topbar.multipleLocationFilter.title" defaultMessage="Lokalizacje" />;
    if (selected[0]?.name) return selected[0]?.name;
    return userLocations.find(({ id }) => selected[0] === id).name;
  }, [selected]);

  return (
    <div className="k-topBarMultipleLocationFilter k-topBar__leftElement">
      <TopBarMultipleChoice
        filterName={filterName}
        objectsToChoose={sortedLocations}
        selectedObjects={selectedObjects}
        onChange={useCallback(
          locations => {
            handleChange(locations);
          },
          [selectedLocations, selected],
        )}
        className="k-topBarChoice--multipleLocationFilter"
        displayKey="name"
        onBlur={useCallback(() => {
          handleBlur();
        }, [selected, changeFilter])}
      />
    </div>
  );
};

TopBarMultipleLocationFilter.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({})),
  changeFilter: PropTypes.func,
  locationType: PropTypes.string,
  selectAllWhenEmpty: PropTypes.bool,
};

export default TopBarMultipleLocationFilter;
