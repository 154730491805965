import { connect } from 'react-redux';

import { createAttendance } from '@/actions/attendances.js';
import { hideModal } from '@/actions/uiState.js';
import { ADD_ATTENDANCE_MODAL } from '@/constants/modalTypes.js';

import CreateAttendanceModal from './CreateAttendanceModal.jsx';

const mapStateToProps = (state, ownProps) => ({
  showModal: state.reducer.uiState.showModal === ADD_ATTENDANCE_MODAL,
  minDate: state.reducer.mainDateStore.customDate.start,
  maxDate: state.reducer.mainDateStore.customDate.end,
  userEmployees: state.reducer.userEmployees,
  userLocations: state.reducer.userLocations,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  companyRoles: state.reducer.roles.companyRoles,
  currentUser: state.reducer.currentUser,
  userPermissions: state.reducer.userPermissions,
  singleEmployeeFilter: state.reducer.singleEmployeeFilter,
  multipleLocationFilter: ownProps.useMultipleLocationFilter
    ? state.reducer.multipleLocationFilter
    : state.reducer.scheduleLocationFilter,
  labels: state.reducer.labels,
  loanEmployeesProposalsAssignments: state.reducer.loanEmployeesProposalsAssignments.data,
});

const mapDispatchToProps = {
  hideModal,
  createAttendance,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAttendanceModal);
