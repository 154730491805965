import PropTypes from 'prop-types';

import MDTable from '@/components/common/MDTable/MDTable';
import { ContextWithIntl } from '@/types/intl.types';

import { getReportTableColumns, prepareData } from './reportsTable.helpers.ts';
import { messages } from './reportsTable.messages.ts';
import { useReportsTable } from './useReportsTable';

import './reportsTable.scss';

const ReportsTable = (_props, { intl }: ContextWithIntl) => {
  const { data, payoutSetting } = useReportsTable();
  const columns = getReportTableColumns(intl, payoutSetting);

  const preparedData = prepareData(data);

  return (
    <div className="k-reportsTable">
      <div className="k-reportsTable__title">{intl.formatMessage(messages.tableTitle)}</div>
      <MDTable
        columns={columns}
        data={preparedData}
        className="k-reportsTable__table"
        withOptions={false}
        withCheckbox={false}
        withSearch={false}
        withSummary
      />
    </div>
  );
};

ReportsTable.contextTypes = {
  intl: PropTypes.shape({}),
};

export default ReportsTable;
