import moment from 'moment';

import { SKIP_WEEKENDS_AND_HOLIDAYS_HIDE } from '@/constants/Restrictions';
import { generateFromToForInitData, getToday } from '@/utils/dateHelper';

import { absenceErrorMessages } from './AbsenceAddModal.messages';

export const modalModifiers = ['narrow'];

export const getInitialState = ({
  absenceTypes,
  userEmployees,
  userId,
  countOnlyDaysWithShiftsSelectedByDefault,
  modalObject,
  restrictions,
  currentUserRole,
}) => {
  const { from: start, to: end } = generateFromToForInitData();

  const selectedEmployee = userEmployees.find(empl => empl.id === userId);
  const selectedEmployeeId =
    selectedEmployee && selectedEmployee.employment_conditions.show_absences ? selectedEmployee.id : null;
  const relevantAbsenceTypes = absenceTypes.filter(
    absenceType =>
      !absenceType.deleted_at_timestamp && (currentUserRole !== 'employee' || absenceType.adding_by_employee),
  );
  const isSkipWeekendsAndHolidaysRestricted = restrictions.includes(SKIP_WEEKENDS_AND_HOLIDAYS_HIDE);

  return {
    selectedAbsence: relevantAbsenceTypes.find(type => type.is_default_uw_absence) || relevantAbsenceTypes[0],
    selectedAbsenceRange: { start, end },
    selectedDay: getToday(),
    selectedEmployeeId: modalObject?.employee?.id || selectedEmployeeId,
    absenceOmitWeekends: !isSkipWeekendsAndHolidaysRestricted,
    absenceOmitHolidays: !isSkipWeekendsAndHolidaysRestricted,
    countOnlyDaysWithShifts: countOnlyDaysWithShiftsSelectedByDefault,
    absenceComment: '',
    absenceHours: '__:__-__:__',
    allDay: true,
    errors: {},
    overtimeCycle: {
      day: 1,
      month: moment().month() + 1,
      year: moment().year(),
    },
    multiplier: '1.5',
    amount50: '00:00',
    amount100: '00:00',
    amountPotential: '00:00',
    availableOvertimes: undefined,
  };
};

export const getInputsToValidate = (selectedAbsence, allDay, showEmployeeSelect) => {
  const inputs = [];
  if (showEmployeeSelect) inputs.push('selectedEmployeeId');
  if ((!allDay && selectedAbsence.defined_in_hours) || selectedAbsence.overtimeCollection) inputs.push('absenceHours');
  if (selectedAbsence.has_note) inputs.push('absenceComment');
  if (selectedAbsence.overtimeCollection) inputs.push('amount50', 'amount100');
  return inputs;
};

export const handleRequestError = err => {
  const errorCode = err.code || 'unknownError';
  return absenceErrorMessages[errorCode];
};

export const getErrorMessage = errors =>
  errors.absencesOverlap || errors.requestError || errors.overtimeCollectionsExceed || errors.scheduleBlocked || '';

export const getLocationsDisabledIds = (locationSettings, start, employeeLocationIds) =>
  Object.entries(locationSettings).reduce(
    (acc, [locationId, { disable_location_schedule_shifts_edit_until: disableLocationScheduleShiftsEditUntil }]) => {
      const isLocationRelevantForEmployee = employeeLocationIds.includes(locationId);
      const isLocationDisabled = start <= disableLocationScheduleShiftsEditUntil;

      if (isLocationDisabled && isLocationRelevantForEmployee) {
        return [...acc, locationId];
      }
      return acc;
    },
    [],
  );
