import { FormattedMessage } from 'react-intl';

import { EXPORT_MODE } from '@/constants/exportConstants.js';

import { messages } from './ExportScheduleModal.messages';

const avabilitiesLegend = {
  type: 'checkbox',
  id: 'showLegend',
  text: 'Legenda dostępności',
};

const absencesLegend = {
  type: 'checkbox',
  id: 'showLegend',
  text: 'Opis i legenda niedostępności',
  tooltip: <FormattedMessage {...messages.exportWithAbsences} />,
};

export const scheduleExportLegends = {
  [EXPORT_MODE.SCHEDULE.ABSENCES]: absencesLegend,
  [EXPORT_MODE.SCHEDULE.AVAILABILITIES]: avabilitiesLegend,
};

export const shiftsFromOtherLocations = {
  type: 'checkbox',
  id: 'showOtherLocations',
  text: 'Zmiany z innych lokalizacji',
};
export const showSummary = {
  type: 'checkbox',
  id: 'showSummary',
  text: 'Podsumowanie zaplanowanych godzin',
};

export const DEFAULT_EXPORT_MODE = EXPORT_MODE.SCHEDULE.ABSENCES;
