import { generateDateArray } from 'kadro-helpers/lib/helpers';
import { defineMessages } from 'react-intl';

import {
  GET_REPORT_DATA_INIT,
  GET_REPORT_DATA_SUCCESS,
  GET_SALES_REPORT,
  GET_SYSTEM_BONUS_EXPORT_DATA_ERROR,
  GET_WORKTIME_REPORT,
  REPORTS_DATE_CHANGE_CUSTOM_DATE,
  REPORTS_DATE_CHANGE_MODE,
  REPORTS_DATE_CHANGE_MODE_AND_DATE,
  REPORTS_DATE_MOVE_LEFT,
  REPORTS_DATE_MOVE_RIGHT,
  REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE,
  REPORTS_JOB_TITLES_FILTER_CHANGE,
  REPORTS_LOCATIONS_FILTER_CHANGE,
} from '@/constants/ActionTypes.js';
import { filterEmployeesByFilters } from '@/utils/userEmployeesHelpers';

import { exportSpmhReportsData } from './exports.js';
import { conn } from './index';
import { getBonuses } from './spmhBasedBonuses.js';
import { decreaseLoaderCounter, increaseLoaderCounter } from './uiState.js';

const messages = defineMessages({
  systemBonusExportDataError: {
    id: 'reports.systemBonusExportDataError',
    defaultMessage: 'Nie udało się wygenerować raportu.',
  },
  noDataReceivedToExport: {
    id: 'reports.noDataReceivedToExport',
    defaultMessage: 'Dla zastosowanych filtrów nie uzyskano danych do eksportu.',
  },
  changeFiltering: {
    id: 'reports.changeFiltering',
    defaultMessage: 'Aby móc wygenerować raport dostosuj filtry',
  },
});

export const getWorkTimeReport = () => async (dispatch, getState) => {
  const { dateStore, filters } = getState().reducer.reports;
  const { selectedLocations, selectedJobTitles, selectedEmploymentConditions } = filters;

  try {
    const { data } = await conn.getWorkTimeReport(
      dateStore.customDate.start,
      dateStore.customDate.end,
      selectedLocations,
      selectedJobTitles,
      selectedEmploymentConditions,
    );

    dispatch({
      type: GET_WORKTIME_REPORT,
      data,
      reportName: 'workTime',
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSalesReport = () => async (dispatch, getState) => {
  const { dateStore, filters } = getState().reducer.reports;
  const { selectedLocations, selectedJobTitles, selectedEmploymentConditions } = filters;

  try {
    const { data } = await conn.getSalesReport(
      dateStore.customDate.start,
      dateStore.customDate.end,
      selectedLocations,
      selectedJobTitles,
      selectedEmploymentConditions,
    );

    dispatch({
      type: GET_SALES_REPORT,
      data,
      reportName: 'sales',
    });
  } catch (error) {
    console.error(error);
  }
};

export const changeReportsLocationsFilter = locations => dispatch => {
  const newLocations = typeof locations[0] === 'object' ? locations.map(({ id }) => id) : locations;

  dispatch({
    type: REPORTS_LOCATIONS_FILTER_CHANGE,
    locations: newLocations,
  });
};

export const changeReportsJobTitlesFilter = jobTitles => ({
  type: REPORTS_JOB_TITLES_FILTER_CHANGE,
  jobTitles,
});

export const changeReportsEmploymentConditionsFilter = employmentConditions => ({
  type: REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE,
  employmentConditions,
});

export const reportsDateChangeMode = dateMode => ({
  type: REPORTS_DATE_CHANGE_MODE,
  payload: dateMode,
});

export const reportsDateMoveLeft = () => ({
  type: REPORTS_DATE_MOVE_LEFT,
});

export const reportsDateMoveRight = () => ({
  type: REPORTS_DATE_MOVE_RIGHT,
});

export const reportsDateChangeCustom = customDate => ({
  type: REPORTS_DATE_CHANGE_CUSTOM_DATE,
  payload: customDate,
});

export const reportsDateChangeModeAndDate = (start, end, dateMode) => ({
  type: REPORTS_DATE_CHANGE_MODE_AND_DATE,
  payload: { start, end, dateMode },
});

const getSystemBonusExportDataError = error => (dispatch, getState, intl) => {
  const errorMessage = getErrorMessageForSystemBonus(error);
  dispatch({
    type: GET_SYSTEM_BONUS_EXPORT_DATA_ERROR,
    notification: {
      title: intl.formatMessage(errorMessage),
      type: 'error',
    },
  });
};

const getErrorMessageForSystemBonus = error => {
  switch (error.message) {
    case 'change_filtering':
      return messages.changeFiltering;
    case 'no_data_to_export':
      return messages.noDataReceivedToExport;
    default:
      return messages.systemBonusExportDataError;
  }
};

export const getSystemBonusExportData = () => async dispatch => {
  try {
    dispatch(increaseLoaderCounter('blocking'));
    const rawData = await dispatch(getBonuses());
    const format = 'XLS';
    await dispatch(exportSpmhReportsData(format, rawData));
  } catch (err) {
    dispatch(getSystemBonusExportDataError(err));
  } finally {
    dispatch(decreaseLoaderCounter('blocking'));
  }
};

export const getReportDataInit = {
  type: GET_REPORT_DATA_INIT,
};

export const getReportDataSuccess = data => dispatch => {
  dispatch({
    type: GET_REPORT_DATA_SUCCESS,
    payload: data,
  });
  dispatch(decreaseLoaderCounter('blocking'));
};

export const getReportDataForCurrentDateRange = () => async (dispatch, getState) => {
  const { dateStore } = getState().reducer.reports;
  const { start: from, end: to } = dateStore.customDate;
  dispatch(getReportData(from, to));
};

export const getReportData = (from, to) => async (dispatch, getState) => {
  const { userEmployees, contracts, reports } = getState().reducer;
  const { selectedLocations, selectedJobTitles, selectedEmploymentConditions } = reports.filters;
  const selectedEmploymentConditionIds = selectedEmploymentConditions.map(condition => condition.id);
  const relevantEmployeeIds = filterEmployeesByFilters(
    userEmployees,
    selectedLocations,
    selectedJobTitles,
    selectedEmploymentConditionIds,
    contracts,
    from,
    to,
  ).map(employee => employee.id);

  const payload = {
    from,
    to,
    dateArray: generateDateArray(from, to),
    locations: selectedLocations,
    job_titles: selectedJobTitles,
    extra_job_titles: [],
    employment_conditions_ids: selectedEmploymentConditionIds,
    employees: relevantEmployeeIds,
    options: {
      overtime: true,
      absences: true,
      availabilities: true,
      freeDays: true,
    },
  };
  dispatch(increaseLoaderCounter('blocking'));
  conn.runExport('report-data-export', payload, 'REPORT_VIEW_DATA');
  dispatch(getReportDataInit);
};
