export const getExportPayrollScheduleSummary = () => [
  { value: 'Suma:', type: 'string' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'number' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'number' },
];
export const getExportPayrollAttendanceSummary = () => [
  { value: 'Suma:', type: 'string' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: 0, type: 'hours' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: 0, type: 'hours' },
  { value: '', type: 'empty' },
  { value: '', type: 'empty' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'number' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'hours' },
  { value: 0, type: 'number' },
];

export const EXPORT_OPTIONS = {
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  LIST_SELECTION: 'LIST_SELECTION',
  DYNAMIC_LIST: 'DYNAMIC_LIST',
  MULTI_SELECT: 'MULTI_SELECT',
};

export const EXPORT_FILTERS = {
  LOCATIONS: 'LOCATIONS',
  JOB_TITLES: 'JOB_TITLES',
  EMPLOYMENT_CONDITIONS: 'EMPLOYMENT_CONDITIONS',
};

export const EXPORT_STEPS = {
  SELECTING_FORMAT: 'SELECTING_FORMAT',
  FILTERING: 'FILTERING',
  CHOOSING_EMPLOYEES: 'CHOOSING_EMPLOYEES',
  SETTINGS_OPTIONS: 'SETTINGS_OPTIONS',
};

export const EXPORT_FILE_TYPES = {
  QR_CODES: 'QR_CODES',
  CSV: 'CSV',
  PDF: 'PDF',
  XLS: 'XLS',
  XML: 'XML',
  XML_ENOVA: 'XMLEnova',
  XML_COMARCH_OPTIMA: 'XMLComarch Optima',
};

export const EXPORTS_FILTERS = 'k-exportsFilters_v2';

export const EXPORT_FORMATS = {
  XLS: 'xls',
  PDF: 'pdf',
  XML: 'xml',
};

export const EXPORT_CATEGORIES = {
  TIME_WORKED: 'timeWorked',
  OTHER_SYSTEMS: 'otherSystems',
  OPERATIONAL: 'operational',
  BUDGET: 'budget',
};

export const COMARCH_XLS = {
  EMPTY: '',
  NO_DATA: 'brak',
};

export const ANNONYMOUS_ABSENCE_NAME = 'Nb';

export const EXPORT_MODE = {
  SCHEDULE: {
    ABSENCES: 'absences',
    AVAILABILITIES: 'availabilities',
  },
};

export const EXPORTS_COLUMNS_VALUE = {
  NAME: 'name',
  LAST_NAME: 'lastName',
  REFERENCE_ID: 'referenceId',
  EMPLOYMENT_CONDITION: 'employmentCondition',
  ROLE: 'role',
  DATE: 'date',
  WEEKDAY: 'weekday',
  LOCATION_NAME: 'locationName',
  LOCATION_EXTRA_INFO: 'locationExtraInfo',
  JOB_TITLE_NAME: 'jobTitleName',
  WAGE: 'wage',
  SHIFT_START: 'shiftStart',
  SHIFT_END: 'shiftEnd',
  PLANNED_DURATION: 'plannedDuration',
  SHIFT_DURATION: 'shiftDuration',
  ATTENDANCE_START: 'attendanceStart',
  ATTENDANCE_END: 'attendanceEnd',
  ATTENDANCE_DURATION: 'attendanceDuration',
  PAYROLL_START: 'payrollStart',
  PAYROLL_END: 'payrollEnd',
  PAYROLL_DURATION: 'payrollDuration',
  ACCOUNTED_DURATION: 'accountedDuration',
  NIGHT_HOURS: 'nightHours',
  BONUS_AMOUNT: 'bonusAmount',
  DIFF_DURATION: 'diffDuration',
  UNDERWORKED_MINUTES: 'underworkedMinutes',
  BREAKS_DURATION: 'breaksDuration',
  ATTENDANCE_LABELS: 'attendanceLabels',
  OVERTIME_50: 'overtime50',
  OVERTIME_100: 'overtime100',
  POTENTIAL_OVERTIME: 'potentialOvertime',
  FILL_UP: 'fillUp',
  PAY_AMOUNT: 'payAmount',
  ABSENCE_NAME: 'absenceName',
  ABSENCE_CODE: 'absenceCode',
  ABSENCE_DURATION: 'absenceDuration',
  ATTENDANCE_GPS: 'attendanceGPS',
  ABSENCES: 'absences',
  AVAILABILITIES: 'availabilities',
};

export const EXPORTS_OPTIONS_NAME = {
  FREE_DAYS: 'freeDays',
  AVAILABILITIES: 'availabilities',
  ABSENCES: 'absences',
  UNFINALIZED: 'unfinalized',
  OVERTIME: 'overtime',
  ATTENDANCE_GPS: 'attendanceGPS',
};

export const COLUMNS_OPTIONS_MAPPING = {
  [EXPORTS_COLUMNS_VALUE.ABSENCES]: EXPORTS_OPTIONS_NAME.ABSENCES,
  [EXPORTS_COLUMNS_VALUE.ATTENDANCE_GPS]: EXPORTS_OPTIONS_NAME.ATTENDANCE_GPS,
  [EXPORTS_COLUMNS_VALUE.AVAILABILITIES]: EXPORTS_OPTIONS_NAME.AVAILABILITIES,
  [EXPORTS_COLUMNS_VALUE.OVERTIME_50]: EXPORTS_OPTIONS_NAME.OVERTIME,
  [EXPORTS_COLUMNS_VALUE.OVERTIME_100]: EXPORTS_OPTIONS_NAME.OVERTIME,
  [EXPORTS_COLUMNS_VALUE.POTENTIAL_OVERTIME]: EXPORTS_OPTIONS_NAME.OVERTIME,
  [EXPORTS_COLUMNS_VALUE.FILL_UP]: EXPORTS_OPTIONS_NAME.OVERTIME,
};
