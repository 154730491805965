import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useBlockLayout, useFilters, useMountedLayoutEffect, useRowSelect, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';

import MDTableTab from '@/components/common/MDTable/MDTableTab';

import { getCheckboxColumn } from './CheckboxColumn';
import { defaultColumn, getShadowColumns } from './MDTable.helpers';
import MDTableBody from './MDTableBody.jsx';
import MDTableHead from './MDTableHead.jsx';
import MDTablePagination from './MDTablePagination.jsx';
import MDTableSummary from './MDTableSummary/MDTableSummary.tsx';
import { getOptionsColumn } from './OptionsColumn';

import './MDTable.scss';

function MDTable(props) {
  const {
    data,
    columns,
    currentPage,
    changeCurrentPage,
    numberOfItemsPerPage,
    changeNumberOfItemsPerPage,
    numberOfPages,
    withPagination,
    withCheckbox,
    tableOptions,
    tableEmpty,
    withOptions,
    withoutHeader,
    withSearch,
    handleSelectedRowChange,
    disableSortBy,
    rowsWithMergedColumns = {},
    withHorizontalScroll = false,
    maxHeight,
    className,
    withBigPadding,
    customOnSelectHandler,
    disableSelectAllCheckbox,
    defaultSortByColumn,
    onRowClick,
    withShadowOnStickyColumns,
    showSkeletons,
    tabs,
    activeTab,
    getRowId,
    withSummary,
    isDescSorting,
  } = props;
  const [activeFilter, setActiveFilter] = useState(false);
  const [activeSelect, setActiveSelect] = useState(false);
  const [pageNumber, setPageNumber] = useState(props.currentPage);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    [],
  );

  const handlePageOnBlur = () => {
    if (!(pageNumber >= 1 && pageNumber <= props.numberOfPages)) {
      setPageNumber(currentPage);
    } else if (pageNumber !== currentPage) {
      props.changeCurrentPage(pageNumber);
    }
  };
  const allColumns = useMemo(
    () =>
      [
        withCheckbox && getCheckboxColumn(columns, customOnSelectHandler, disableSelectAllCheckbox),
        ...columns,
        withOptions && getOptionsColumn(props.options),
      ].filter(Boolean),
    [columns, props.options, withOptions, withCheckbox],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    columns: cols,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds, sortBy },
  } = useTable(
    {
      columns: allColumns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSortBy: false,
      autoResetPage: false,
      manualSortBy: props.onSort,
      disableSortBy,
      getRowId,
    },
    useBlockLayout,
    useFilters,
    useSortBy,
    useRowSelect,
    useSticky,
  );

  useEffect(() => {
    if (!showSkeletons) {
      const relevantSortByColumn = sortBy?.length
        ? cols.find(col => col.id === sortBy[0]?.id)
        : cols.find(col => col.sortingKey === defaultSortByColumn);
      if (relevantSortByColumn?.toggleSortBy) {
        relevantSortByColumn.toggleSortBy(isDescSorting);
      }
    }
  }, [showSkeletons]);

  useMountedLayoutEffect(() => {
    if (handleSelectedRowChange) {
      const selectedIds = Object.keys(selectedRowIds);
      const selectedRowsData = selectedIds.map(x => data[x]).filter(x => x !== null);

      handleSelectedRowChange(selectedRowsData);
    }
  }, [handleSelectedRowChange, selectedRowIds]);

  useEffect(() => {
    if (props.onSort) {
      props.onSort(sortBy);
    }
  }, [sortBy]);

  const tableClassName = classnames('mdTable', className, {
    mdTable__withCheckbox: withCheckbox,
  });

  const { shadowRightColumn, shadowLeftColumn } = useMemo(
    () => getShadowColumns(cols, { withShadowOnStickyColumns, withHorizontalScroll }),
    [cols, withHorizontalScroll, withShadowOnStickyColumns],
  );

  const visibleRows = useMemo(() => rows.map(row => row.original), [rows]);

  return (
    <div className={tableClassName}>
      {tableOptions || withSearch ? (
        <div className="mdTable__settings">
          <div className="mdTable__options">
            {tableOptions &&
              React.cloneElement(tableOptions, {
                selectedData: selectedFlatRows.map(selected => selected.original),
                setActiveFilter,
                visibleRows,
              })}
          </div>
          {withSearch && (
            <span
              role="button"
              tabIndex={0}
              onClick={() => setActiveFilter(active => !active)}
              className="mdTable__filterIcon material-icons"
            >
              search
            </span>
          )}
        </div>
      ) : null}
      {tabs?.length > 0 && (
        <div className="mdTable__tabs">
          {tabs.map(tab => (
            <MDTableTab tab={tab} isActive={tab.id === activeTab} key={tab.id} />
          ))}
        </div>
      )}
      <div
        style={{ maxHeight }}
        className={classnames('mdTable__container', {
          'mdTable__container--withHorizontalScroll': withHorizontalScroll,
        })}
      >
        <div
          style={{ maxHeight }}
          className={classnames('mdTable__table', 'sticky', {
            'mdTable__table--withHorizontalScroll': withHorizontalScroll,
          })}
          {...getTableProps()}
        >
          {!withoutHeader && (
            <MDTableHead
              headerGroups={headerGroups}
              withBigPadding={withBigPadding}
              shadowRightColumn={shadowRightColumn}
              shadowLeftColumn={shadowLeftColumn}
            />
          )}
          <MDTableBody
            rows={rows}
            cols={cols}
            getTableBodyProps={getTableBodyProps}
            activeFilter={activeFilter}
            prepareRow={prepareRow}
            options={props.options}
            withCheckbox={withCheckbox}
            withOptions={withOptions}
            empty={data.length === 0}
            tableEmpty={tableEmpty}
            rowsWithMergedColumns={rowsWithMergedColumns}
            withHorizontalScroll={withHorizontalScroll}
            withBigPadding={withBigPadding}
            onRowClick={onRowClick}
            shadowRightColumn={shadowRightColumn}
            shadowLeftColumn={shadowLeftColumn}
          />
          {withSummary && (
            <MDTableSummary
              columns={cols}
              data={data}
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              withHorizontalScroll={withHorizontalScroll}
              withBigPadding={withBigPadding}
              shadowRightColumn={shadowRightColumn}
              shadowLeftColumn={shadowLeftColumn}
            />
          )}
        </div>
      </div>

      {withPagination && data.length !== 0 && (
        <MDTablePagination
          currentPage={currentPage}
          changeCurrentPage={changeCurrentPage}
          numberOfPages={numberOfPages}
          activeSelect={activeSelect}
          setActiveSelect={setActiveSelect}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          handlePageOnBlur={handlePageOnBlur}
          numberOfItemsPerPage={numberOfItemsPerPage}
          changeNumberOfItemsPerPage={changeNumberOfItemsPerPage}
        />
      )}
    </div>
  );
}

MDTable.defaultProps = {
  tableEmpty: <div />,
  withOptions: true,
  withoutHeader: false,
  withSearch: true,
  withBigPadding: false,
};

MDTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
    }),
  ),
  currentPage: PropTypes.number,
  changeCurrentPage: PropTypes.func,
  numberOfItemsPerPage: PropTypes.number,
  changeNumberOfItemsPerPage: PropTypes.func,
  numberOfPages: PropTypes.number,
  withPagination: PropTypes.bool,
  options: PropTypes.node,
  tableOptions: PropTypes.node,
  tableEmpty: PropTypes.node,
  withCheckbox: PropTypes.bool,
  withOptions: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  withSearch: PropTypes.bool,
  handleSelectedRowChange: PropTypes.func,
  disableSortBy: PropTypes.bool,
  onSort: PropTypes.func,
  rowsWithMergedColumns: PropTypes.shape({}),
  withHorizontalScroll: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  withBigPadding: PropTypes.bool,
  customOnSelectHandler: PropTypes.func,
  disableSelectAllCheckbox: PropTypes.bool,
  defaultSortByColumn: PropTypes.string,
  onRowClick: PropTypes.func,
  withShadowOnStickyColumns: PropTypes.bool,
  showSkeletons: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      onSelect: PropTypes.func,
      color: PropTypes.string,
    }),
  ),
  activeTab: PropTypes.string,
  getRowId: PropTypes.func,
  withSummary: PropTypes.bool,
  isDescSorting: PropTypes.bool,
};

export default MDTable;
