import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import ExportModal from '@/components/common/exports/ExportModal/ExportModal.redux';
import { defaultAvailabilityTypes } from '@/constants/availabilityDefaultTypes.js';
import {
  FILE_CSV_BACKGROUND,
  FILE_CSV_COLOR,
  FILE_PDF_BACKGROUND,
  FILE_PDF_COLOR,
  FILE_XLS_BACKGROUND,
  FILE_XLS_COLOR,
  FILE_XML_BACKGROUND,
  FILE_XML_COLOR,
} from '@/constants/colors.js';
import { EXPORT_MODE } from '@/constants/exportConstants.js';

import {
  DEFAULT_EXPORT_MODE,
  scheduleExportLegends,
  shiftsFromOtherLocations,
  showSummary,
} from './ExportScheduleModalConstants';
import { getExportOptions, getListProp } from './exportScheduleModalHelpers';

const ExportScheduleModal = (props, { intl }) => {
  const { absencesTypes, displayModeType, userCustomTypes } = props;

  const [exportMode, setExportMode] = useState(DEFAULT_EXPORT_MODE);

  const shouldShowComarchOptimaExport = displayModeType === EXPORT_MODE.SCHEDULE.AVAILABILITIES;
  const availabilitiesTypes = [
    ...defaultAvailabilityTypes.map(type => ({ ...type, name: intl.formatMessage(type.name) })),
    ...userCustomTypes,
  ];

  const legend = scheduleExportLegends[exportMode];
  const listProp = getListProp(exportMode, { absencesTypes, availabilitiesTypes });
  const formats = useMemo(
    () =>
      [
        { text: 'CSV', backgroundColor: FILE_CSV_BACKGROUND, color: FILE_CSV_COLOR, options: [] },
        { text: 'XLS', backgroundColor: FILE_XLS_BACKGROUND, color: FILE_XLS_COLOR, options: [] },
        {
          text: 'PDF',
          backgroundColor: FILE_PDF_BACKGROUND,
          color: FILE_PDF_COLOR,
          options: [shiftsFromOtherLocations, showSummary, getExportOptions(setExportMode), legend, listProp],
        },
        { text: 'XML', backgroundColor: FILE_XML_BACKGROUND, color: FILE_XML_COLOR, subtitle: 'Enova', options: [] },
      ].concat(
        shouldShowComarchOptimaExport
          ? {
              text: 'XML',
              backgroundColor: FILE_XML_BACKGROUND,
              color: FILE_XML_COLOR,
              subtitle: 'Comarch Optima',
              options: [listProp],
            }
          : [],
      ),
    [displayModeType, exportMode, userCustomTypes],
  );

  const handleHide = () => {
    setExportMode(DEFAULT_EXPORT_MODE);
  };

  return <ExportModal {...props} onHide={handleHide} formats={formats} />;
};

ExportScheduleModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ExportScheduleModal.propTypes = {
  absencesTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  displayModeType: PropTypes.oneOf(Object.values(EXPORT_MODE.SCHEDULE)),
  userCustomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};
export default ExportScheduleModal;
