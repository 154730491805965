import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import { EMPLOYEE_PAYROLL_VIEW_SHOW } from '@/constants/Permissions';
import { permissionsDescriptionLangs, permissionsDescriptionLinks, permissionsGroupLangs } from '@/constants/Roles.js';
import { getEmployeeRank } from '@/utils/userEmployeesHelpers.js';

import RolesTableHeader from '../RolesTableHeader/RolesTableHeader.redux';
import RolesTableLeftRow from '../RolesTableLeftRow/RolesTableLeftRow.redux';
import RolesTableRow from '../RolesTableRow/RolesTableRow.redux';

import './RolesTable.scss';

const TABLE_MAX_HEIGHT = Number.MAX_SAFE_INTEGER;

const messages = defineMessages({
  clickHere: {
    id: 'roles.permission.clickHere',
    defaultMessage: 'KLIKNIJ TUTAJ',
  },
});

class RolesTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      width: 0,
    };
    this.generateTooltip = this.generateTooltip.bind(this);
  }

  componentWillMount() {
    this.setState({
      width: this.props.companyRoles.reduce((prev, _) => prev + 240, 0),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      width: nextProps.companyRoles.reduce((prev, _) => prev + 240, 0),
    });
  }

  generateTooltip(permissionId) {
    const desc = permissionsDescriptionLangs[permissionId];
    const link = desc && permissionsDescriptionLinks[permissionId];

    return (
      desc && (
        <FormattedMessage
          {...desc}
          values={{
            clickHere: link && (
              <a target="_blank" rel="noreferrer" href={this.context.intl.formatMessage(link)}>
                {this.context.intl.formatMessage(messages.clickHere)}
              </a>
            ),
          }}
        />
      )
    );
  }

  render() {
    const { companyRoles, grouppedPermissions, currentUser, companySettings } = this.props;
    const currentUserRank = getEmployeeRank(currentUser, companyRoles);
    const rolesWithEditPermission = companyRoles.map(role => ({ ...role, canEdit: role.rank < currentUserRank }));

    const filteredPermissionsIds = Object.keys(grouppedPermissions).filter(id => permissionsGroupLangs[id]);
    const groupedPermissionsWithTooltips = filteredPermissionsIds.reduce((acc, cur) => {
      const newPerms = grouppedPermissions[cur]
        .sort((a, b) => a.order - b.order)
        .map(perm => {
          const tooltip = this.generateTooltip(perm.id);
          return tooltip ? { ...perm, tooltip } : perm;
        })
        .filter(({ id }) => !(id === EMPLOYEE_PAYROLL_VIEW_SHOW && !companySettings.employee_ecp_enabled));

      return { ...acc, [cur]: newPerms };
    }, {});

    const leftRows = filteredPermissionsIds.map(id => {
      const groupName = this.context.intl.formatMessage(permissionsGroupLangs[id], {});

      return (
        <RolesTableLeftRow
          group={{ id, name: groupName }}
          permissions={groupedPermissionsWithTooltips[id]}
          showDetails={false}
          key={id}
        />
      );
    });

    const rows = filteredPermissionsIds.map(id => (
      <RolesTableRow
        groupId={id}
        grouppedPermissions={grouppedPermissions[id]}
        roles={rolesWithEditPermission}
        key={id}
      />
    ));

    return (
      <div className="k-rolesTable">
        <div style={{ clear: 'both' }}>
          <Grid
            width={this.state.width}
            maxHeight={TABLE_MAX_HEIGHT}
            columnStyle={{ width: '22vw', minWidth: '260px' }}
            dontUpdateInlineHeight
          >
            <LeftHeader className="k-rolesTable__leftHeader">
              <th className="tab_title" />
            </LeftHeader>
            <Header className="k-rolesTable__header">
              <RolesTableHeader roles={rolesWithEditPermission} />
            </Header>
            <LeftColumn className="table k-rolesTable__leftColumn">{leftRows}</LeftColumn>
            <Rows className="table k-rolesTable__rows">{rows}</Rows>
          </Grid>
        </div>
      </div>
    );
  }
}

RolesTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

RolesTable.propTypes = {
  grouppedPermissions: PropTypes.shape({}),
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
  currentUser: PropTypes.shape({
    role_id: PropTypes.oneOf([PropTypes.string, null]),
    role: PropTypes.string,
  }),
  companySettings: PropTypes.shape({
    employee_ecp_enabled: PropTypes.bool,
  }),
};
export default RolesTable;
