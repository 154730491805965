import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import KadroCalendarPopoverInput from '../../inputs/KadroCalendarPopoverInput.jsx';
import MDTextInput from '../../inputs/MDTextInput/MDTextInput.jsx';

const messages = defineMessages({
  dayPickerInput: {
    id: 'dayPicker.label',
    defaultMessage: 'Powtarzaj co',
  },
});

const DayPicker = (
  { calText, selectedRange, changeRange, minDate, maxDate, perWhichDay, setPerWhichDay },
  { intl },
) => (
  <div>
    <fieldset className="form-group">
      <label htmlFor="czas">
        <FormattedMessage id="common.period" defaultMessage="Okres" />
      </label>
      <KadroCalendarPopoverInput
        position="top"
        inputValue={calText}
        selectedRange={selectedRange}
        onRangeChange={changeRange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </fieldset>
    <MDTextInput
      value={perWhichDay}
      onChange={e => setPerWhichDay(e.target.value)}
      type="number"
      id="perWhichDay"
      label={intl.formatMessage(messages.dayPickerInput)}
      modifiers={['modal']}
    />
  </div>
);

DayPicker.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

DayPicker.propTypes = {
  calText: PropTypes.string,
  changeRange: PropTypes.func,
  selectedRange: PropTypes.shape({}),
  perWhichDay: PropTypes.string,
  setPerWhichDay: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default DayPicker;
