import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import KebabMenu from '@/components/common/Basic/KebabMenu/KebabMenu';
import { ADD_ABSENCE_PROPOSAL_MODAL, PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL } from '@/constants/modalTypes';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions.js';
import { isAttendanceEditDisabled } from '@/utils/attendanceHelpers';

import { messages } from './PayrollTableOptions.messages';

import './PayrollTableOptions.scss';

const PayrollTableOptions = (
  {
    showModal,
    rowData,
    selectedEmployee,
    userPermissions,
    payrollMode,
    currentUser,
    showAcceptOvertimeModalForPayroll,
    companyRoles,
    userEmployees,
  },
  { intl },
) => {
  const showPayrollEditShiftAndAttendance = useCallback(
    isLoanedEmployee => {
      showModal(PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL, {
        shiftId: rowData.shiftId,
        attendanceId: rowData.attendanceId,
        selectedEmployee,
        isLoanedEmployee,
      });
    },
    [rowData.shiftId, rowData.attendanceId, selectedEmployee],
  );

  const isAttendanceEditRestricted = useMemo(
    () => isAttendanceEditDisabled(userPermissions, currentUser, selectedEmployee, companyRoles),
    [userPermissions, currentUser, selectedEmployee, companyRoles],
  );

  const isShiftEditDisabled = useMemo(
    () => userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE) || (rowData.isLoaned && !rowData.attendanceId),
    [userPermissions, rowData],
  );

  const isSupervisor = useMemo(() => userEmployees.some(employee => employee.id === selectedEmployee.id), [
    userEmployees,
    selectedEmployee,
  ]);

  const isEditingModeEnabled = payrollMode === PAYROLL_MODES.editable;

  const editLabel = isEditingModeEnabled ? (
    intl.formatMessage(messages.edit)
  ) : (
    <div className="k-payrollTableOptions__label">
      {intl.formatMessage(messages.editBlocked)}
      <i className="material-icons-outlined">lock_open</i>
    </div>
  );

  const isAbsence = !!rowData.absenceId;
  const isShiftOrAttendance = !!rowData.attendanceId || !!rowData.shiftId;
  const isLoanedEmployee = !!rowData.isLoanedEmployee;
  const { hasOvertimeToAccept } = rowData;
  const options = [
    {
      id: '0',
      icon: 'create',
      title: intl.formatMessage(messages.edit),
      onClick: () => showPayrollEditShiftAndAttendance(isLoanedEmployee),
      show: isShiftOrAttendance && (!isShiftEditDisabled || !isAttendanceEditRestricted),
    },
    {
      id: '1',
      icon: 'more_time',
      title: intl.formatMessage(messages.acceptOvertime),
      onClick: async () => {
        await showAcceptOvertimeModalForPayroll(rowData.attendanceId, rowData.shiftId);
      },
      show: !isLoanedEmployee && hasOvertimeToAccept && !isAttendanceEditRestricted,
    },
    {
      id: '2',
      icon: 'history',
      title: intl.formatMessage(messages.absenceHistory),
      onClick: () => showModal(ADD_ABSENCE_PROPOSAL_MODAL, { id: rowData.absenceId, employee_id: selectedEmployee.id }),
      show: !isLoanedEmployee && isAbsence && isSupervisor,
    },
  ];
  const relevantOptions = options.filter(option => option.show);
  const kebabMenuDisabled = !isEditingModeEnabled || relevantOptions.length === 0;
  return <KebabMenu title={editLabel} options={relevantOptions} disabled={kebabMenuDisabled} />;
};

PayrollTableOptions.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollTableOptions.propTypes = {
  showModal: PropTypes.func,
  rowData: PropTypes.shape({
    shiftId: PropTypes.string,
    attendanceId: PropTypes.string,
    absenceId: PropTypes.string,
    realizationVsPlan: PropTypes.string,
    workHours: PropTypes.string,
    plannedTime: PropTypes.number,
    workTime: PropTypes.string,
    difference: PropTypes.number,
    hasOvertimeToAccept: PropTypes.bool,
    isLoanedEmployee: PropTypes.bool,
  }),
  selectedEmployee: PropTypes.shape({
    id: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  payrollMode: PropTypes.string,
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  showAcceptOvertimeModalForPayroll: PropTypes.func,
  companyRoles: PropTypes.shape({}),
};

export default PayrollTableOptions;
