import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { useLocation } from 'react-router';

import { KADRO_LIGHT_ORANGE_COLOR } from '@/constants/colors';
import { initialMinMaxDates } from '@/constants/dates';
import { getMinAndMaxDate, getStaticCalendarRanges } from '@/utils/calendarHelpers';
import { getMinMaxDatesIncludedHireAndReleaseDate, getToday } from '@/utils/dateHelper';
import { getLocaleConfig } from '@/utils/locale/locale.utils';

import './KadroCalendarRange.scss';

const KadroCalendarRange = (props, context) => {
  const {
    isMobileView,
    selectedRange,
    mainDateStore,
    modes,
    hidePicker,
    initialMinMaxDatesEnable,
    setLocalDateRanges,
    minDate,
    maxDate,
  } = props;
  const currentRoute = useLocation().pathname;
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [minMaxDates, setMinMaxDates] = useState(initialMinMaxDates);
  const [selection, setSelection] = useState([
    {
      startDate: new Date(selectedRange.start || getToday()),
      endDate: new Date(selectedRange.end || getToday()),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setSelection([
      {
        startDate: new Date(selectedRange.start || getToday()),
        endDate: new Date(selectedRange.end || getToday()),
        key: 'selection',
      },
    ]);
    if (minDate || maxDate) {
      setMinMaxDates(getMinMaxDatesIncludedHireAndReleaseDate(minDate, maxDate));
    }
  }, [selectedRange]);

  const staticRanges = hidePicker ? [] : getStaticCalendarRanges(mainDateStore, modes, selectedRange);
  const predefinedRanges = createStaticRanges(staticRanges);
  const calendarClassName = classnames('kadroCalendarRange', {
    'kadroCalendarRange--hidePicker': hidePicker,
  });

  const onChange = (item) => {
    setSelection([item.selection]);
    const isEndDateSelected = focusedRange[1] === 1;
    const { startDate: start } = item.selection;
    if (setLocalDateRanges) {
      setLocalDateRanges({ start, end: item.selection.endDate }, isEndDateSelected);
    }
    if (isEndDateSelected || item.selection.isStaticRange) {
      const { endDate: end } = item.selection;
      setTimeout(() => props.onChange({ start, end }), 10);
      if (!minDate && !maxDate) setMinMaxDates(initialMinMaxDates);
    } else if (initialMinMaxDatesEnable) {
      setMinMaxDates(getMinAndMaxDate(start, currentRoute, initialMinMaxDatesEnable));
    }
  };

  return (
    <div className={calendarClassName}>
      <DateRangePicker
        staticRanges={predefinedRanges}
        focusedRange={focusedRange}
        onRangeFocusChange={setFocusedRange}
        onChange={onChange}
        moveRangeOnFirstSelection={false}
        ranges={selection}
        direction="horizontal"
        months={isMobileView ? 1 : 2}
        weekStartsOn={1}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        locale={getLocaleConfig(context.intl.locale).dateFnsLocale}
        monthDisplayFormat="LLLL yyyy"
        rangeColors={[KADRO_LIGHT_ORANGE_COLOR]}
        preventSnapRefocus
        calendarFocus="backwards"
        minDate={minMaxDates.min}
        maxDate={minMaxDates.max}
      />
    </div>
  );
};

KadroCalendarRange.defaultProps = {
  mainDateStore: {},
  modes: [],
  hidePicker: false,
  initialMinMaxDatesEnable: false,
};

KadroCalendarRange.contextTypes = {
  intl: PropTypes.shape({}),
};

KadroCalendarRange.propTypes = {
  hidePicker: PropTypes.bool,
  selectedRange: PropTypes.shape({
    start: PropTypes.shape({}),
    end: PropTypes.shape({}),
  }),
  onChange: PropTypes.func,
  mainDateStore: PropTypes.shape({}),
  isMobileView: PropTypes.bool,
  modes: PropTypes.arrayOf(PropTypes.number),
  initialMinMaxDatesEnable: PropTypes.bool,
  setLocalDateRanges: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default KadroCalendarRange;
