import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';
import { IntlShape } from 'react-intl';

import { TableData } from '@/types/reports.types';
import { roundToTwoSigDigits } from '@/utils/baseHelpers';

import { messages } from './reportsTable.columns.messages';

export const COLUMNS_FOR_SHIFTS_PAYOUT_SETTINGS = [
  'date',
  'plannedMinutes',
  'employeesCount',
  'plannedLaborCost',
  'absencesMinutes',
];

export const getReportTableColumns = (
  intl: IntlShape,
  payoutSetting: { id: number; type: string; message: string },
) => {
  const isShiftType = payoutSetting.type === 'shifts';

  return [
    {
      id: 0,
      accessor: 'date',
      width: 108,
      Header: intl.formatMessage(messages.date),
      sticky: 'left',
      showSortIcon: true,
    },
    {
      id: 1,
      accessor: 'plannedMinutes',
      width: 144,
      Header: intl.formatMessage(messages.plannedMinutes),
      showSortIcon: true,
      Cell: ({ value }) => parseMinutesToHumanForm(value),
    },
    {
      id: 2,
      accessor: 'workedMinutes',
      width: 144,
      Header: intl.formatMessage(messages.workedMinutes),
      showSortIcon: true,
      Cell: ({ value }) => parseMinutesToHumanForm(value),
    },
    {
      id: 3,
      accessor: 'differenceMinutes',
      width: 144,
      Header: intl.formatMessage(messages.differenceMinutes),
      showSortIcon: true,
      Cell: ({ value }) => parseMinutesToHumanForm(value),
    },

    {
      id: 4,
      accessor: 'differencePercent',
      width: 144,
      Header: intl.formatMessage(messages.differencePercent),
      showSortIcon: true,
      Cell: ({ value }) => `${value.toFixed(2)} %`,
    },
    {
      id: 5,
      accessor: 'employeesCount',
      width: 144,
      Header: intl.formatMessage(messages.employeesCount),
      showSortIcon: true,
    },
    {
      id: 6,
      accessor: 'frequency',
      width: 144,
      Header: intl.formatMessage(messages.frequency),
      showSortIcon: true,
      Cell: ({ value }) => `${value} %`,
    },
    {
      id: 7,
      accessor: 'plannedLaborCost',
      width: 144,
      Header: intl.formatMessage(messages.plannedLaborCost),
      showSortIcon: true,
      Cell: ({ value }) => `${roundToTwoSigDigits(value / 100)} PLN`,
    },
    {
      id: 8,
      accessor: 'actualLaborCost',
      width: 144,
      Header: intl.formatMessage(messages.actualLaborCost),
      showSortIcon: true,
      Cell: ({ value }) => `${roundToTwoSigDigits(value / 100)} PLN`,
    },
    {
      id: 9,
      accessor: 'differenceCost',
      width: 144,
      Header: intl.formatMessage(messages.differenceCost),
      showSortIcon: true,
      Cell: ({ value }) => `${roundToTwoSigDigits(value / 100)} PLN`,
    },
    {
      id: 10,
      accessor: 'differenceCostPercent',
      width: 144,
      Header: intl.formatMessage(messages.differenceCostPercent),
      showSortIcon: true,
      Cell: ({ value }) => `${value.toFixed(2)} %`,
    },
    {
      id: 11,
      accessor: 'absencesMinutes',
      width: 144,
      Header: intl.formatMessage(messages.absencesMinutes),
      showSortIcon: true,
      Cell: ({ value }) => parseMinutesToHumanForm(value),
    },
  ].filter(column => !isShiftType || COLUMNS_FOR_SHIFTS_PAYOUT_SETTINGS.includes(column.accessor));
};

export const prepareData = (data: Record<string, TableData>) =>
  Object.entries(data).map(([date, columnDate]) => ({
    ...columnDate,
    date,
  }));
