import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import KadroCalendarPopoverInput from '@/components/common/inputs/KadroCalendarPopoverInput.jsx';
import { KadroMultiSelect, MDMultiSelectHeader } from '@/components/common/inputs/MDComponents';
import { exportsModesIds } from '@/constants/viewDateModes';
import { convertDateToStandardFormat, getCalendarRangeText } from '@/utils/dateHelper.js';
import {
  getEmploymentConditionsMessages,
  getJobTitlesMessages,
  getLocationsMessages,
} from '@/utils/filters/filters.helpers';
import { exportMessages } from '@/utils/filters/filters.messages';
import { createEvent } from '@/utils/inputHelpers.js';

const ExportFiltersStep = (props, { intl }) => {
  const { selectedRange, multiSelects, mainDateStore } = props;

  const jobTitlesMessages = useMemo(() => getJobTitlesMessages(intl, multiSelects.jobTitlesItems), [
    multiSelects.jobTitlesItems,
  ]);
  const locationsMessages = useMemo(() => getLocationsMessages(intl, multiSelects.locationsItems), [
    multiSelects.locationsItems,
  ]);
  const employmentConditionsMessages = useMemo(
    () => getEmploymentConditionsMessages(intl, multiSelects.employmentConditionsItems),
    [multiSelects.employmentConditionsItems],
  );

  const changeSelectedRange = useCallback(
    e =>
      props.handlers.changeInput(
        createEvent('selectedRange', {
          start: convertDateToStandardFormat(e.start),
          end: convertDateToStandardFormat(e.end),
        }),
      ),
    [],
  );

  return (
    <div>
      <MDMultiSelectHeader
        title={intl.formatMessage(exportMessages.exportFiltersStepDateRangeTitle)}
        withoutSelectAll
        withoutDeselectAll
      />
      <KadroCalendarPopoverInput
        inputValue={getCalendarRangeText(selectedRange.start, selectedRange.end)}
        selectedRange={selectedRange}
        onRangeChange={changeSelectedRange}
        inputEnabled
        hidePicker={false}
        mainDateStore={mainDateStore}
        modes={exportsModesIds}
      />
      <KadroMultiSelect
        messages={locationsMessages}
        items={multiSelects.locationsItems}
        handlers={multiSelects.locationsHandlers}
        withoutFooter
      />
      <KadroMultiSelect
        messages={jobTitlesMessages}
        items={multiSelects.jobTitlesItems}
        handlers={multiSelects.jobTitlesHandlers}
        withoutFooter
      />
      <KadroMultiSelect
        messages={employmentConditionsMessages}
        items={multiSelects.employmentConditionsItems}
        handlers={multiSelects.employmentConditionsHandlers}
        withoutFooter
      />
    </div>
  );
};

ExportFiltersStep.contextTypes = {
  intl: PropTypes.shape({}),
};

ExportFiltersStep.propTypes = {
  selectedRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  handlers: PropTypes.shape({
    changeInput: PropTypes.func,
  }),
  multiSelects: PropTypes.shape({
    jobTitlesHandlers: PropTypes.shape({}),
    jobTitlesItems: PropTypes.arrayOf(PropTypes.shape({})),
    locationsHandlers: PropTypes.shape({}),
    locationsItems: PropTypes.arrayOf(PropTypes.shape({})),
    employmentConditionsItems: PropTypes.arrayOf(PropTypes.shape({})),
    employmentConditionsHandlers: PropTypes.shape({}),
  }),
  mainDateStore: PropTypes.shape({}),
};

export default ExportFiltersStep;
