import { DateModeEnum, ViewDateModes } from '@/types/mainDateStore';

import {
  DATE_MODE_CUSTOM_ID,
  DATE_MODE_DAY_ID,
  DATE_MODE_MONTH_ID,
  DATE_MODE_WEEK_ID,
  DATE_MODE_YEAR_ID,
} from './dateModes';

export const viewDateModes: ViewDateModes = {
  '/schedule': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
    maxDayDiff: 45,
  },
  '/attendance': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.DAY,
    maxDayDiff: 45,
  },
  '/payroll': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
  },
  '/payroll_location': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
  },
  '/reports': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
  },
  '/production_quotas': {
    allowed: [DateModeEnum.DAY, DateModeEnum.MONTH],
    default: DateModeEnum.DAY,
  },
  '/availabilities': {
    allowed: [DateModeEnum.CUSTOM, DateModeEnum.WEEK, DateModeEnum.MONTH],
    default: DateModeEnum.WEEK,
  },
  '/exports': {
    allowed: [DateModeEnum.CUSTOM, DateModeEnum.WEEK, DateModeEnum.MONTH],
    default: DateModeEnum.WEEK,
    maxDayDiff: 92,
  },
  '/timesheet': {
    allowed: [DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
  },
  '/newPayroll': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
  },
  '/newPayrollLocation': {
    allowed: [DateModeEnum.DAY, DateModeEnum.WEEK, DateModeEnum.MONTH, DateModeEnum.CUSTOM],
    default: DateModeEnum.WEEK,
  },
};

export const commonDatePages = [
  '/schedule',
  '/payroll',
  '/payroll_location',
  '/reports',
  '/timesheet',
  '/newPayroll',
  '/newPayrollLocation',
];

export const pagesWithCalendar = [
  '/schedule',
  '/payroll',
  '/payroll_location',
  '/reports',
  '/attendance',
  '/production_quotas',
  '/availabilities',
  '/exports',
  '/timesheet',
  '/newPayroll',
  '/newPayrollLocation',
];

export const absencesLimitsModesIdsForEmployee = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_YEAR_ID];
export const absencesLimitsModesIdsForManager = [DATE_MODE_WEEK_ID];
export const attendanceModesIds = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID, DATE_MODE_CUSTOM_ID];
export const newReportsModesIds = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID, DATE_MODE_CUSTOM_ID];
export const availabilityModesIds = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID, DATE_MODE_CUSTOM_ID];
export const exportsModesIds = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID, DATE_MODE_CUSTOM_ID];
export const payrollModesIdsForEmployee = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID];
export const payrollModesIdsForManager = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_CUSTOM_ID, DATE_MODE_DAY_ID];
export const payrollLocationModesIds = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_CUSTOM_ID, DATE_MODE_DAY_ID];
export const employeePayrollModesIds = [DATE_MODE_MONTH_ID];
export const productionQuotasModesIds = [DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID];
export const reportsModesIds = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_CUSTOM_ID, DATE_MODE_DAY_ID];
export const absencesModesIds = [DATE_MODE_MONTH_ID, DATE_MODE_CUSTOM_ID, DATE_MODE_YEAR_ID];
export const scheduleModesIdsForEmployee = [DATE_MODE_WEEK_ID, DATE_MODE_MONTH_ID, DATE_MODE_DAY_ID];
export const scheduleModesIdsForManager = [
  DATE_MODE_WEEK_ID,
  DATE_MODE_MONTH_ID,
  DATE_MODE_DAY_ID,
  DATE_MODE_CUSTOM_ID,
];
export const timesheetModesIdsForManager = [
  DATE_MODE_WEEK_ID,
  DATE_MODE_MONTH_ID,
  DATE_MODE_DAY_ID,
  DATE_MODE_CUSTOM_ID,
];
