import classNames from 'classnames';
import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import { KadroCalendarMultiple, KadroCalendarRange } from '@/components/common/KadroCalendars';
import { payrollLocationModesIds } from '@/constants/viewDateModes';
import { convertDateToStandardFormat } from '@/utils/dateHelper.js';

import Popover from '../Popover/Popover.jsx';

const messages = defineMessages({
  placeholder: {
    id: 'common.calendarPopoverInput.placeholder',
    defaultMessage: 'Kliknij by zaznaczyć okres',
  },
});

class KadroCalendarPopoverInput extends Component {
  constructor(props, context) {
    super(props, context);
    bindClassFunctions(this);
  }

  calendarChange(e) {
    this.popover.hide();
    this.props.onRangeChange(e);
  }

  changeDate(date) {
    const formattedDate = convertDateToStandardFormat(date);
    this.popover.hide();
    this.props.changeDate(formattedDate);
  }

  render() {
    const {
      inputValue,
      position,
      selectedRange,
      onInputClick,
      modifiers,
      singleDay,
      customDate,
      label,
      inputEnabled,
      hidePicker,
      mainDateStore,
      modes,
      minDate,
      maxDate,
    } = this.props;

    const { intl } = this.context;

    const modifiersList = Array.isArray(modifiers) ? modifiers : modifiers?.split(' ');
    const className = classNames(
      'k-calendarPopoverInput',
      ...(modifiersList || []).map(modifier => `k-calendarPopoverInput--${modifier}`),
    );
    const inputClassName = classNames('k-calendarPopoverInput__input form-control k-textInput', {
      'k-textInput--disabled': !inputEnabled,
    });

    return (
      <div onClick={onInputClick} className={className} role="presentation">
        <Popover
          ref={ref => {
            this.popover = ref;
          }}
          position={position}
          sideOffset={550}
          content={
            singleDay ? (
              <KadroCalendarMultiple onChange={this.changeDate} selected={[customDate]} />
            ) : (
              <KadroCalendarRange
                onChange={this.calendarChange}
                selectedRange={this.props.selectedRange}
                hidePicker={this.props.hidePicker}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                modes={payrollLocationModesIds}
                mainDateStore={this.props.mainDateStore}
              />
            )
          }
        >
          <div>
            {label && <label className="k-calendarPopoverInput__label">{label}</label>}
            <input
              type="text"
              className={inputClassName}
              id="od"
              placeholder={intl.formatMessage(messages.placeholder, {})}
              value={inputValue}
              readOnly={!inputEnabled}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

KadroCalendarPopoverInput.defaultProps = {
  hidePicker: true,
};

KadroCalendarPopoverInput.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

KadroCalendarPopoverInput.defaultProps = {
  hidePicker: true,
};

KadroCalendarPopoverInput.propTypes = {
  inputValue: PropTypes.string,
  position: PropTypes.string,
  selectedRange: PropTypes.shape({}),
  onInputClick: PropTypes.func,
  onRangeChange: PropTypes.func,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  singleDay: PropTypes.bool,
  customDate: PropTypes.string,
  changeDate: PropTypes.func,
  label: PropTypes.string,
  inputEnabled: PropTypes.bool,
  hidePicker: PropTypes.bool,
  mainDateStore: PropTypes.shape({}),
  modes: PropTypes.arrayOf(PropTypes.string),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

export default KadroCalendarPopoverInput;
