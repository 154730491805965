import { generateDateArray } from 'kadro-helpers/lib/helpers';
import moment from 'moment';
import { v4 } from 'uuid';

import * as AT from '@/constants/ActionTypes';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { getDatesRangeWithDayBeforeAndAfterFromDates } from '@/utils/dateHelper';

import { getInitPayrollColumns, getInitSummaryValues } from './payroll.helpers';

const initialState = {
  data: [],
  summary: {},
  isLoadButtonEnabled: false,
  visibleColumns: getInitPayrollColumns(),
  mode: PAYROLL_MODES.readonly,
  summaryValues: getInitSummaryValues(),
  isFetching: false,
  currentRequestId: null,
};

const addRowId = rows =>
  rows.map(row => ({
    ...row,
    rowId: v4(),
  }));

const markRowAsRefreshingDates = (rows, dates) =>
  rows.map(row => {
    const startDate = dates.reduce((result, date) => (date < result ? date : result), dates[0]);
    const endDate = dates.reduce((result, date) => (date > result ? date : result), dates[0]);
    const { from, to } = getDatesRangeWithDayBeforeAndAfterFromDates(startDate, endDate);
    if (dates.includes(row.date)) {
      return {
        ...row,
        isRefreshing: true,
      };
    }

    if (row.date >= from && row.date <= to) {
      return {
        ...row,
        isRefreshingOvertime: true,
      };
    }

    return row;
  });

const payroll = (state = initialState, action) => {
  switch (action.type) {
    case AT.START_FETCHING_PAYROLL:
      return { ...state, isFetching: true, currentRequestId: action.payload.fetchRequestId };
    case AT.PAYROLL_GET_DATA_SUCCESS: {
      const {
        options,
        data: { data: newData, summary },
        from,
        to,
        status,
        fetchRequestId,
      } = action.payload;

      if (fetchRequestId !== state.currentRequestId) {
        return state;
      }

      if (status) {
        const filteredOldData =
          status === 'all'
            ? []
            : state.data.filter(row => {
                if (status === 'unapproved') {
                  return !row.isUnapproved;
                }
                return row.isUnapproved;
              });

        return {
          ...state,
          isFetching: false,
          data: addRowId([...filteredOldData, ...newData]).sort((a, b) => a.date.localeCompare(b.date)),
          summary,
          currentRequestId: null,
        };
      }

      if (options?.mergeWithOldData) {
        const filteredOldData = state.data.filter(row => row.date < from || row.date > to);
        return {
          ...state,
          data: addRowId([...filteredOldData, ...newData]).sort((a, b) => a.date.localeCompare(b.date)),
          isLoadButtonEnabled: false,
          isFetching: false,
          currentRequestId: null,
        };
      }

      return {
        ...state,
        ...action.payload.data,
        data: addRowId(newData),
        isLoadButtonEnabled: false,
        isFetching: false,
        currentRequestId: null,
      };
    }
    case AT.PAYROLL_GET_DATA_ERROR:
      if (action.payload.fetchRequestId !== state.currentRequestId) {
        return state;
      }
      return { ...state, data: [], summary: {}, isFetching: false, currentRequestId: null };
    case AT.PAYROLL_CHANGE_VISIBLE_COLUMNS:
      return { ...state, visibleColumns: action.payload };
    case AT.PAYROLL_CHANGE_MODE: {
      return { ...state, mode: action.payload };
    }
    case AT.ENABLE_PAYROLL_LOAD_BUTTON:
    case AT.CHANGE_JOBTITLE_FILTER:
    case AT.CHANGE_MULTIPLE_LOCATION_FILTER:
    case AT.CHANGE_SINGLE_EMPLOYEE_FILTER:
    case AT.DELETE_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.ADD_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.DELETE_ATTENDANCE_SUCCESFUL:
    case AT.ADD_SHIFT_SUCCESFUL:
    case AT.ADD_SHIFTS_SUCCESFUL:
    case AT.EDIT_SHIFT_SUCCESFUL:
    case AT.DELETE_SHIFT:
    case AT.DELETE_MASS_SHIFTS_SUCCESFUL:
    case AT.ADD_MASS_SHIFTS_SUCCESFUL:
    case AT.EDIT_MASS_SHIFTS_SUCCESFUL:
    case AT.PUBLISH_DRAFT_SHIFTS_SUCCESFUL:
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL:
    case AT.SWAP_SHIFTS_SUCCESS:
    case AT.ADD_AVAILABILITY_SUCCESFUL:
    case AT.DELETE_AVAILABILITY_SUCCESFUL:
    case AT.CHANGE_AVAILABILITY_SUCCESFUL:
    case AT.DELETE_AVAILABILITIES_SUCCESFUL:
    case AT.ADD_ATTENDANCE_SUCCESFUL:
    case AT.CHANGE_ATTENDANCE_SUCCESFUL:
    case AT.CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL:
    case AT.START_ATTENDANCE_SUCCESFUL:
    case AT.END_ATTENDANCE_SUCCESFUL:
    case AT.START_BREAK_SUCCESFUL:
    case AT.END_BREAK_SUCCESFUL:
    case AT.UPDATE_EMPLOYMENT_CONDITION:
    case AT.ADD_ABSENCE_SUCCESS:
    case AT.EDIT_ABSENCE_SUCCESS:
    case AT.DELETE_ABSENCE_SUCCESS:
    case AT.REJECT_ABSENCE_SUCCESS:
      return { ...state, isLoadButtonEnabled: true };
    case AT.CHANGE_PAYROLL_DAYS_STATUS_SUCCESS: {
      const { dates, newStatus } = action.payload;
      return {
        ...state,
        data: state.data.map(row => {
          if (dates.includes(row.date)) {
            return {
              ...row,
              isUnapproved: newStatus === 'unapproved',
            };
          }

          return row;
        }),
      };
    }
    case AT.EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS:
    case AT.ADD_ATTENDANCES_OVERTIME_SUCCESS: {
      const {
        attendance: { date },
      } = action.payload;
      const newData = markRowAsRefreshingDates(state.data, [date]);
      return {
        ...state,
        data: newData,
      };
    }
    case AT.DELETE_BREAK_SUCCESFUL:
    case AT.ADD_BREAK_SUCCESFUL:
    case AT.CHANGE_BREAK_SUCCESFUL: {
      const restBreak = action.payload;
      const date = moment(restBreak.start_timestamp).format('YYYY-MM-DD');
      const newData = markRowAsRefreshingDates(state.data, [date]);
      return {
        ...state,
        data: newData,
      };
    }
    case AT.EDIT_SHIFT_FOR_PAYROLL_SUCCESS: {
      const { shift } = action.payload;
      const newData = markRowAsRefreshingDates(state.data, [shift.date]);
      return {
        ...state,
        data: newData,
      };
    }
    case AT.ACCEPT_ABSENCE_SUCCESS: {
      const absence = action.payload;
      const dateArray = generateDateArray(absence.from, absence.to);
      const newData = markRowAsRefreshingDates(state.data, dateArray);
      return {
        ...state,
        data: newData,
      };
    }
    case AT.ADD_ATTENDANCE_BONUS_SUCCESFUL: {
      const { attendance, bonus_amount: bonusAmount } = action.payload;
      const newData = state.data.map(row => {
        if (attendance.id === row.attendanceId) {
          return {
            ...row,
            bonusAmount: bonusAmount / 100,
            isRefreshing: true,
          };
        }

        return row;
      });
      return {
        ...state,
        data: newData,
      };
    }
    case AT.CHOOSE_PAYROLL_SUMMARY_VALUES: {
      const { newItems } = action.payload;
      return {
        ...state,
        summaryValues: newItems,
      };
    }

    case AT.CHANGE_PAYROLL_SETTING: {
      const { type } = action.payload.payoutSetting;
      const initSummaryValues = getInitSummaryValues();
      const isPayoutSettingsShiftsType = type === 'shifts';

      const newSummaryValues = isPayoutSettingsShiftsType
        ? initSummaryValues.reduce((acc, value) => {
            if (value.id !== 'overtime') {
              acc.push(value.id === 'bonuses' ? { ...value, active: true } : value);
            }
            return acc;
          }, [])
        : initSummaryValues;

      return {
        ...state,
        summaryValues: newSummaryValues,
      };
    }
    case AT.ASSIGN_LABEL_TO_ATTENDANCE:
    case AT.UNASSIGN_LABEL_FROM_ATTENDANCE: {
      const { attendanceId } = action.payload;
      const newData = state.data.map(row => {
        if (attendanceId === row.attendanceId) {
          return {
            ...row,
            isRefreshing: true,
          };
        }
        return row;
      });
      return {
        ...state,
        data: newData,
      };
    }

    default:
      return state;
  }
};

export default payroll;
